import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MqttActions } from '../actions';
import { MqttEventType } from '../../models/mqtt.model';

@Injectable()
export class MqttEffects {
  handleEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MqttActions.event),
        tap((action) => {
          this.handleEvent(action.event);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}

  handleEvent(event: MqttEventType) {}
}
