import { createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions';
import {
  UserEventMetadata,
  UserEventType,
  UserStatusType,
} from '../../models/api/user.model';
import { ApiEvent } from '../../models/api/api.model';
import { Organization } from '../../models/api/organization.model';
import { Environment } from '../../models/api/environment.model';
import {
  Role,
  RoleResourceType,
  RoleStatusType,
} from '../../models/api/role.model';
import { ClusterService } from '../../models/api/cluster-service.model';
import { ApiResponse } from '../../models/api/api-response.model';
import { UserSetActiveEnvironmentResponse } from '../../models/api/response/users/user-set-active-environment-response.model';
import { UserSetActiveOrganizationResponse } from '../../models/api/response/users/user-set-active-organization-response.model';

export interface UserState {
  _id: string;
  email: string;
  username: string;
  ipa_id: string;
  piv_cert: string;
  piv_cn: string;
  first_name: string;
  last_name: string;
  phone_prefix: string;
  phone: string;
  status: UserStatusType;
  events: Array<ApiEvent<UserEventType, UserEventMetadata>>;
  active_organization: Organization;
  active_environment: Environment;
  active_cluster_service: ClusterService;
  roles: Role[];
}

export const initialUserState: UserState = {
  _id: undefined,
  email: undefined,
  username: undefined,
  ipa_id: undefined,
  piv_cert: undefined,
  piv_cn: undefined,
  first_name: undefined,
  last_name: undefined,
  phone_prefix: undefined,
  phone: undefined,
  status: undefined,
  events: undefined,
  active_organization: undefined,
  active_environment: undefined,
  active_cluster_service: undefined,
  roles: undefined,
};

export const userReducer = createReducer(
  initialUserState,

  on(UserActions.load, (state, action) => {
    return {
      ...state,
      _id: action._id,
      email: action.email,
      username: action.username,
      ipa_id: action.ipa_id,
      piv_cert: action.piv_cert,
      piv_cn: action.piv_cn,
      first_name: action.first_name,
      last_name: action.last_name,
      phone_prefix: action.phone_prefix,
      phone: action.phone,
      status: action.status,
      events: action.events,
      active_organization: action.active_organization,
      active_environment: action.active_environment,
      active_cluster_service: action.active_cluster_service,
      roles: action.roles,
    };
  }),

  on(UserActions.addRole, (state, role) => {
    return {
      ...state,
      roles: [...state.roles, role],
    };
  }),

  on(UserActions.updateRole, (state, role) => {
    return {
      ...state,
      roles: state.roles.map((existingRole) =>
        existingRole._id === role._id ? role : existingRole
      ),
    };
  }),

  on(UserActions.removeRole, (state, role) => {
    let active_environment = state.active_environment;

    const roles = state.roles.filter((r) => r._id !== role._id);

    const baseEnvironmentIds = state.roles
      .filter(
        (r) =>
          r.resource === RoleResourceType.ORGANIZATION &&
          r.status === RoleStatusType.ENABLED
      )
      .map((r) => r.organization.base_environment._id);

    if (
      active_environment &&
      role.resource === RoleResourceType.ENVIRONMENT &&
      role.environment._id === active_environment._id
    ) {
      const remainingEnvironmentRoles = roles.filter(
        (roleObj) =>
          roleObj.resource === RoleResourceType.ENVIRONMENT &&
          roleObj.status === RoleStatusType.ENABLED &&
          roleObj.environment.namespace.startsWith('env-') &&
          !baseEnvironmentIds.includes(roleObj.environment._id)
      );

      if (remainingEnvironmentRoles.length === 1) {
        active_environment = remainingEnvironmentRoles[0].environment;
      } else {
        active_environment = undefined;
      }
    }

    return {
      ...state,
      roles,
      active_environment,
    };
  }),

  on(UserActions.setActiveOrganization, (state, organization) => {
    return {
      ...state,
      active_organization: organization,
      active_environment: null,
    };
  }),

  on(UserActions.unsetActiveOrganization, (state) => {
    return {
      ...state,
      active_organization: undefined,
    };
  }),

  on(UserActions.setActiveEnvironment, (state, environment) => {
    if (environment._id !== state.active_organization.base_environment._id) {
      return {
        ...state,
        active_environment: environment,
      };
    } else {
      return state;
    }
  }),

  on(UserActions.unsetActiveEnvironment, (state) => {
    return {
      ...state,
      active_environment: undefined,
    };
  }),

  on(UserActions.setActiveClusterService, (state, clusterService) => {
    return {
      ...state,
      active_cluster_service: clusterService,
    };
  }),

  on(UserActions.clearActiveClusterService, (state) => {
    return {
      ...state,
      active_cluster_service: undefined,
    };
  }),

  on(UserActions.attachFileSystem, (state, fileSystem) => {
    if (!state.active_cluster_service) {
      return state;
    }

    return {
      ...state,
      active_cluster_service: {
        ...state.active_cluster_service,
        attached_file_systems: state.active_cluster_service
          .attached_file_systems
          ? [...state.active_cluster_service.attached_file_systems, fileSystem]
          : [fileSystem],
      },
    };
  }),

  on(UserActions.detachFileSystem, (state, fileSystem) => {
    if (
      !state.active_cluster_service ||
      !state.active_cluster_service.attached_file_systems
    ) {
      return state;
    }

    return {
      ...state,
      active_cluster_service: {
        ...state.active_cluster_service,
        attached_file_systems:
          state.active_cluster_service.attached_file_systems.filter(
            (fs) => fs._id !== fileSystem._id
          ),
      },
    };
  }),

  on(
    UserActions.setActiveOrganizationResponse,
    (state, response: ApiResponse<UserSetActiveOrganizationResponse>) => {
      return {
        ...state,
        active_organization:
          response.success &&
          response.data.role &&
          response.data.role.organization
            ? response.data.role.organization
            : state.active_organization,
      };
    }
  ),

  on(
    UserActions.setActiveEnvironmentResponse,
    (state, response: ApiResponse<UserSetActiveEnvironmentResponse>) => {
      return {
        ...state,
        active_environment: response.success
          ? response.data.role.environment
          : state.active_environment,
      };
    }
  )
);
