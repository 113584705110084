import { createAction, props } from '@ngrx/store';

import { AccountRegistration } from '../../models/api/account-registration.model';

export const invalidRegistrationToken = createAction(
  '[RegistrationAction] Invalid Registration Token'
);

export const load = createAction(
  '[RegistrationAction] Load',
  props<AccountRegistration>()
);
