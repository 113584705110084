import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthState } from '../../store/reducers/auth.reducers';
import { AuthActions } from '../../store/actions';
import { ConfigResponse } from '../../models/api/response/config/config-response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth: AuthState = null;

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private keycloakService: KeycloakService
  ) {
    this.keycloakService.keycloakEvents$.subscribe((event) => {
      this.store.dispatch(AuthActions.updateStatus({ status: event.type }));
      if (event.type === KeycloakEventType.OnTokenExpired) {
        this.refreshToken(30).then((isRefreshed) => {
          if (isRefreshed) {
            this.store.dispatch(
              AuthActions.login({
                jwt: this.keycloakService.getKeycloakInstance().token,
              })
            );
          }
        });
      }
    });
  }

  async refreshToken(minValidity: number): Promise<boolean> {
    return this.keycloakService.updateToken(minValidity);
  }

  async initKeycloak(config: ConfigResponse): Promise<void> {
    return this.keycloakService
      .init({
        config: {
          url: `https://idp.${config.env}.analyticshq.com`,
          realm: config.realm,
          clientId: config.client,
        },
        initOptions: {
          checkLoginIframe: false,
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
        },
        bearerExcludedUrls: ['/assets'],
      })
      .then(() => this.keycloakService.getToken())
      .then((jwt) => {
        this.store.dispatch(
          AuthActions.login({
            jwt,
          })
        );
      });
  }

  async logout(): Promise<void> {
    this.store.dispatch(AuthActions.logout());
    this.modalService.dismissAll();

    if (await this.keycloakService.isLoggedIn()) {
      return this.keycloakService.logout();
    } else {
      return Promise.resolve();
    }
  }
}
