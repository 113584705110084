import { MessagePayload } from '../payload.message';
import { MessageTopics } from '../topics.message';
import { Organization } from '../../organization.model';
import { User } from '../../user.model';

export class OrganizationRegistrationSubmittedMessage extends MessagePayload<{
  organization: Organization;
  user: User;
}> {
  constructor(organization: Organization, user: User) {
    super({
      title: 'Org Registration Submitted',
      description: `Registration Submitted for ${organization.name}`,
      icon: 'Communication/Chat-check.svg',
      topic: MessageTopics.OrganizationRegistrationSubmittedTopic(organization),
      success: true,
      message: 'Organization Registration Completed',
      data: { organization, user },
    });
  }
}
