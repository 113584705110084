import { ApiEvent, ApiModel } from './api.model';

import { User } from './user.model';
import { Organization } from './organization.model';
import { Environment } from './environment.model';
import { FileSystem } from './file-system.model';
import { PostgresCluster } from './postgres-cluster.model';
import { ResourceCapacity } from './resource-capacity.model';
import { AccountRegistration } from './account-registration.model';
import { S3Bucket } from './s3-bucket.model';
import { HdfsCluster } from './hdfs-cluster.model';
import { Notebook } from './notebook.model';
import { Ide } from './ide.model';
import { Elasticsearch } from './elasticsearch.model';
import { Kibana } from './kibana.model';
import { Kafka } from './kafka.model';
import { KafkaConnector } from './kafka-connector.model';
import { KafkaTopic } from './kafka-topic.model';
import { KafkaUser } from './kafka-user.model';
import { KafkaConnect } from './kafka-connect.model';
import { ServiceMonitor } from './service-monitor.model';
import { Alertmanager } from './alertmanager.model';
import { AlertmanagerConfig } from './alertmanager-config.model';
import { PerconaServerMongoDb } from './percona-server-mongo-db.model';
import { PerconaServerMongoDbBackup } from './percona-server-mongo-db-backup.model';
import { PerconaServerMongoDbRestore } from './percona-server-mongo-db-restore.model';
import { PerconaXtraDbCluster } from './percona-xtra-db-cluster.model';
import { PerconaXtraDbClusterBackup } from './percona-xtra-db-cluster-backup.model';
import { PerconaXtraDbClusterRestore } from './percona-xtra-db-cluster-restore.model';
import { VolumeSnapshot } from './volume-snapshot.model';
import { Bundle } from './bundle.model';
import { Redis } from './redis.model';
import { LimitRange } from './limit-range.model';
import { ResourceQuota } from './resource-quota.model';
import { Querybook } from './querybook.model';
import { Zookeeper } from './zookeeper.model';
import { Workflow } from './workflow.model';
import { Certificate } from './certificate.model';
import { EnvoyFilter } from './envoy-filter.model';
import { Gateway } from './gateway.model';
import { NetworkPolicy } from './network-policy.model';
import { CiliumNetworkPolicy } from './cilium-network-policy.model';
import { VirtualService } from './virtual-service.model';
import { Prometheus } from './prometheus.model';
import { DestinationRule } from './destination-rule.model';
import { Issuer } from './issuer.model';
import { Pod } from './pod.model';
import { Deployment } from './deployment.model';
import { StatefulSet } from './stateful-set.model';
import { DaemonSet } from './daemon-set.model';
import { ReplicaSet } from './replica-set.model';
import { Job } from './job.model';
import { Service } from './service.model';
import { PersistentVolumeClaim } from './persistent-volume-claim.model';
import { ConfigMap } from './config-map.model';
import { Secret } from './secret.model';

export enum RolePlatformType {
  PLATFORM_OWNER = 'PLATFORM_OWNER',
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  PLATFORM_USER = 'PLATFORM_USER',
}

export enum RoleEnforcementType {
  NONE = 'NONE',
  ALLOW_IF_ONE = 'ALLOW_IF_ONE',
  ALLOW_IF_ALL = 'ALLOW_IF_ALL',
  ALLOW_ALL = 'ALLOW_ALL',
  DENY_IF_ONE = 'DENY_IF_ONE',
  DENY_IF_ALL = 'DENY_IF_ALL',
  DENY_ALL = 'DENY_ALL',
}

export enum RolePersonaType {
  DATA_ENGINEER = 'DATA_ENGINEER',
  DATA_SCIENTIST = 'DATA_SCIENTIST',
  SOFTWARE_ENGINEER = 'SOFTWARE_ENGINEER',
  BUSINESS_ANALYST = 'BUSINESS_ANALYST',
  BUSINESS_USER = 'BUSINESS_USER',
}

export enum RoleStatusType {
  CREATED = 'CREATED',
  REGISTERING = 'REGISTERING',
  PENDING = 'PENDING',
  REVOKED = 'REVOKED',
  DENIED = 'DENIED',
  ENABLED = 'ENABLED',
  REINSTATED = 'REINSTATED',
  DISABLED = 'DISABLED',
  ARCHIVED = 'ARCHIVED',
}

export enum RoleAccessType {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum RoleResourceType {
  NODE = 'NODE',
  PLATFORM = 'PLATFORM',
  STRIPE_PRICE = 'STRIPE_PRICE',
  ACCOUNT_REGISTRATION = 'ACCOUNT_REGISTRATION',
  TEMPORAL_WORKFLOW = 'TEMPORAL_WORKFLOW',
  RESOURCE_CAPACITY = 'RESOURCE_CAPACITY',
  USER = 'USER',
  POD = 'POD',
  DEPLOYMENT = 'DEPLOYMENT',
  STATEFUL_SET = 'STATEFUL_SET',
  DAEMON_SET = 'DAEMON_SET',
  REPLICA_SET = 'REPLICA_SET',
  JOB = 'JOB',
  SERVICE = 'SERVICE',
  PERSISTENT_VOLUME_CLAIM = 'PERSISTENT_VOLUME_CLAIM',
  CONFIG_MAP = 'CONFIG_MAP',
  SECRET = 'SECRET',
  ROLE = 'ROLE',
  ORGANIZATION = 'ORGANIZATION',
  ENVIRONMENT = 'ENVIRONMENT',
  ISSUER = 'ISSUER',
  CERTIFICATE = 'CERTIFICATE',
  USER_CAPACITY = 'USER_CAPACITY',
  NETWORK_POLICY = 'NETWORK_POLICY',
  CILIUM_NETWORK_POLICY = 'CILIUM_NETWORK_POLICY',
  GATEWAY = 'GATEWAY',
  VIRTUAL_SERVICE = 'VIRTUAL_SERVICE',
  DESTINATION_RULE = 'DESTINATION_RULE',
  ENVOY_FILTER = 'ENVOY_FILTER',
  LIMIT_RANGE = 'LIMIT_RANGE',
  RESOURCE_QUOTA = 'RESOURCE_QUOTA',
  VOLUME_SNAPSHOT = 'VOLUME_SNAPSHOT',
  FILE_SYSTEM = 'FILE_SYSTEM',
  BUNDLE = 'BUNDLE',
  S3_BUCKET = 'S3_BUCKET',
  PROMETHEUS = 'PROMETHEUS',
  HDFS_CLUSTER = 'HDFS_CLUSTER',
  POSTGRES_CLUSTER = 'POSTGRES_CLUSTER',
  ELASTICSEARCH = 'ELASTICSEARCH',
  REDIS = 'REDIS',
  KIBANA = 'KIBANA',
  ZOOKEEPER = 'ZOOKEEPER',
  QUERYBOOK = 'QUERYBOOK',
  WORKFLOW = 'WORKFLOW',
  NOTEBOOK = 'NOTEBOOK',
  IDE = 'IDE',
  KAFKA = 'KAFKA',
  KAFKA_CONNECT = 'KAFKA_CONNECT',
  KAFKA_CONNECTOR = 'KAFKA_CONNECTOR',
  KAFKA_TOPIC = 'KAFKA_TOPIC',
  KAFKA_USER = 'KAFKA_USER',
  SERVICE_MONITOR = 'SERVICE_MONITOR',
  ALERTMANAGER = 'ALERTMANAGER',
  ALERTMANAGER_CONFIG = 'ALERTMANAGER_CONFIG',
  PERCONA_SERVER_MONGO_DB = 'PERCONA_SERVER_MONGO_DB',
  PERCONA_SERVER_MONGO_DB_BACKUP = 'PERCONA_SERVER_MONGO_DB_BACKUP',
  PERCONA_SERVER_MONGO_DB_RESTORE = 'PERCONA_SERVER_MONGO_DB_RESTORE',
  PERCONA_XTRA_DB_CLUSTER = 'PERCONA_XTRA_DB_CLUSTER',
  PERCONA_XTRA_DB_CLUSTER_BACKUP = 'PERCONA_XTRA_DB_CLUSTER_BACKUP',
  PERCONA_XTRA_DB_CLUSTER_RESTORE = 'PERCONA_XTRA_DB_CLUSTER_RESTORE',
}

export enum RoleEventType {
  STATUS_MODIFIED = 'STATUS_MODIFIED',
  ACCESS_MODIFIED = 'ACCESS_MODIFIED',
  ROLE_CREATED = 'ROLE_CREATED',
  ROLE_ATTACHED = 'ROLE_ATTACHED',
  ROLE_ENABLED = 'ROLE_ENABLED',
  ROLE_DISABLED = 'ROLE_DISABLED',
  ROLE_ARCHIVED = 'ROLE_ARCHIVED',

  IPA_ID_SET = 'IPA_ID_SET',
  NODE_ATTACHED = 'NODE_ATTACHED',
  STRIPE_PRICE_ATTACHED = 'STRIPE_PRICE_ATTACHED',
  ACCOUNT_REGISTRATION_ATTACHED = 'ACCOUNT_REGISTRATION_ATTACHED',
  TEMPORAL_WORKFLOW_ATTACHED = 'TEMPORAL_WORKFLOW_ATTACHED',
  RESOURCE_CAPACITY_ATTACHED = 'RESOURCE_CAPACITY_ATTACHED',
  USER_ATTACHED = 'USER_ATTACHED',
  POD_ATTACHED = 'POD_ATTACHED',
  DEPLOYMENT_ATTACHED = 'DEPLOYMENT_ATTACHED',
  STATEFUL_SET_ATTACHED = 'STATEFUL_SET_ATTACHED',
  DAEMON_SET_ATTACHED = 'DAEMON_SET_ATTACHED',
  REPLICA_SET_ATTACHED = 'REPLICA_SET_ATTACHED',
  JOB_ATTACHED = 'JOB_ATTACHED',
  SERVICE_ATTACHED = 'SERVICE_ATTACHED',
  PERSISTENT_VOLUME_CLAIM_ATTACHED = 'PERSISTENT_VOLUME_CLAIM_ATTACHED',
  CONFIG_MAP_ATTACHED = 'CONFIG_MAP_ATTACHED',
  SECRET_ATTACHED = 'SECRET_ATTACHED',
  ORGANIZATION_ATTACHED = 'ORGANIZATION_ATTACHED',
  ENVIRONMENT_ATTACHED = 'ENVIRONMENT_ATTACHED',
  ISSUER_ATTACHED = 'ISSUER_ATTACHED',
  CERTIFICATE_ATTACHED = 'CERTIFICATE_ATTACHED',
  NETWORK_POLICY_ATTACHED = 'NETWORK_POLICY_ATTACHED',
  CILIUM_NETWORK_POLICY_ATTACHED = 'CILIUM_NETWORK_POLICY_ATTACHED',
  GATEWAY_ATTACHED = 'GATEWAY_ATTACHED',
  VIRTUAL_SERVICE_ATTACHED = 'VIRTUAL_SERVICE_ATTACHED',
  DESTINATION_RULE_ATTACHED = 'DESTINATION_RULE_ATTACHED',
  ENVOY_FILTER_ATTACHED = 'ENVOY_FILTER_ATTACHED',
  LIMIT_RANGE_ATTACHED = 'LIMIT_RANGE_ATTACHED',
  RESOURCE_QUOTA_ATTACHED = 'RESOURCE_QUOTA_ATTACHED',
  VOLUME_SNAPSHOT_ATTACHED = 'VOLUME_SNAPSHOT_ATTACHED',
  FILE_SYSTEM_ATTACHED = 'FILE_SYSTEM_ATTACHED',
  BUNDLE_ATTACHED = 'BUNDLE_ATTACHED',
  USER_CAPACITY_ATTACHED = 'USER_CAPACITY_ATTACHED',
  S3_BUCKET_ATTACHED = 'S3_BUCKET_ATTACHED',
  PROMETHEUS_ATTACHED = 'PROMETHEUS_ATTACHED',
  HDFS_CLUSTER_ATTACHED = 'HDFS_CLUSTER_ATTACHED',
  POSTGRES_CLUSTER_ATTACHED = 'POSTGRES_CLUSTER_ATTACHED',
  ELASTICSEARCH_ATTACHED = 'ELASTICSEARCH_ATTACHED',
  REDIS_ATTACHED = 'REDIS_ATTACHED',
  KIBANA_ATTACHED = 'KIBANA_ATTACHED',
  ZOOKEEPER_ATTACHED = 'ZOOKEEPER_ATTACHED',
  QUERYBOOK_ATTACHED = 'QUERYBOOK_ATTACHED',
  WORKFLOW_ATTACHED = 'WORKFLOW_ATTACHED',
  NOTEBOOK_ATTACHED = 'NOTEBOOK_ATTACHED',
  IDE_ATTACHED = 'IDE_ATTACHED',
  KAFKA_ATTACHED = 'KAFKA_ATTACHED',
  KAFKA_CONNECT_ATTACHED = 'KAFKA_CONNECT_ATTACHED',
  KAFKA_CONNECTOR_ATTACHED = 'KAFKA_CONNECTOR_ATTACHED',
  KAFKA_TOPIC_ATTACHED = 'KAFKA_TOPIC_ATTACHED',
  KAFKA_USER_ATTACHED = 'KAFKA_USER_ATTACHED',
  SERVICE_MONITOR_ATTACHED = 'SERVICE_MONITOR_ATTACHED',
  ALERTMANAGER_ATTACHED = 'ALERTMANAGER_ATTACHED',
  ALERTMANAGER_CONFIG_ATTACHED = 'ALERTMANAGER_CONFIG_ATTACHED',
  PERCONA_SERVER_MONGO_DB_ATTACHED = 'PERCONA_SERVER_MONGO_DB_ATTACHED',
  PERCONA_SERVER_MONGO_DB_BACKUP_ATTACHED = 'PERCONA_SERVER_MONGO_DB_BACKUP_ATTACHED',
  PERCONA_SERVER_MONGO_DB_RESTORE_ATTACHED = 'PERCONA_SERVER_MONGO_DB_RESTORE_ATTACHED',
  PERCONA_XTRA_DB_CLUSTER_ATTACHED = 'PERCONA_XTRA_DB_CLUSTER_ATTACHED',
  PERCONA_XTRA_DB_CLUSTER_BACKUP_ATTACHED = 'PERCONA_XTRA_DB_CLUSTER_BACKUP_ATTACHED',
  PERCONA_XTRA_DB_CLUSTER_RESTORE_ATTACHED = 'PERCONA_XTRA_DB_CLUSTER_RESTORE_ATTACHED',
}

export interface RoleEventMetadata {
  status_from?: RoleStatusType;
  status_to?: RoleStatusType;
  access_from?: RoleAccessType;
  access_to?: RoleAccessType;
  ipa_id?: string;
  account_registration?: AccountRegistration;
  resource_capacity?: ResourceCapacity;
  user?: User;
  pod?: Pod;
  deployment?: Deployment;
  stateful_set?: StatefulSet;
  daemon_set?: DaemonSet;
  replica_set?: ReplicaSet;
  job?: Job;
  service?: Service;
  persistent_volume_claim?: PersistentVolumeClaim;
  config_map?: ConfigMap;
  secret?: Secret;
  organization?: Organization;
  environment?: Environment;
  issuer?: Issuer;
  certificate?: Certificate;
  network_policy?: NetworkPolicy;
  cilium_network_policy?: CiliumNetworkPolicy;
  gateway?: Gateway;
  virtual_service?: VirtualService;
  destination_rule?: DestinationRule;
  envoy_filter?: EnvoyFilter;
  limit_range?: LimitRange;
  resource_quota?: ResourceQuota;
  volume_snapshot?: VolumeSnapshot;
  file_system?: FileSystem;
  bundle?: Bundle;
  s3_bucket?: S3Bucket;
  prometheus?: Prometheus;
  hdfs_cluster?: HdfsCluster;
  postgres_cluster?: PostgresCluster;
  elasticsearch?: Elasticsearch;
  redis?: Redis;
  kibana?: Kibana;
  zookeeper?: Zookeeper;
  querybook?: Querybook;
  workflow?: Workflow;
  notebook?: Notebook;
  ide?: Ide;
  kafka?: Kafka;
  kafka_connect?: KafkaConnect;
  kafka_connector?: KafkaConnector;
  kafka_topic?: KafkaTopic;
  kafka_user?: KafkaUser;
  service_monitor?: ServiceMonitor;
  alertmanager?: Alertmanager;
  alertmanager_config?: AlertmanagerConfig;
  percona_server_mongo_db?: PerconaServerMongoDb;
  percona_server_mongo_db_backup?: PerconaServerMongoDbBackup;
  percona_server_mongo_db_restore?: PerconaServerMongoDbRestore;
  percona_xtra_db_cluster?: PerconaXtraDbCluster;
  percona_xtra_db_cluster_backup?: PerconaXtraDbClusterBackup;
  percona_xtra_db_cluster_restore?: PerconaXtraDbClusterRestore;
}

export class Role extends ApiModel {
  static schema_version: number = 1;

  resource: RoleResourceType;
  access: RoleAccessType;
  persona: RolePersonaType;
  ipa_id: string;
  status: RoleStatusType;
  events: Array<ApiEvent<RoleEventType, RoleEventMetadata>>;
  account_registration: AccountRegistration;
  resource_capacity: ResourceCapacity;
  user: User;
  pod: Pod;
  deployment: Deployment;
  stateful_set: StatefulSet;
  daemon_set: DaemonSet;
  replica_set?: ReplicaSet;
  job: Job;
  service: Service;
  persistent_volume_claim: PersistentVolumeClaim;
  config_map: ConfigMap;
  secret: Secret;
  organization: Organization;
  environment: Environment;
  issuer: Issuer;
  certificate: Certificate;
  network_policy: NetworkPolicy;
  cilium_network_policy: CiliumNetworkPolicy;
  gateway: Gateway;
  virtual_service: VirtualService;
  destination_rule: DestinationRule;
  envoy_filter: EnvoyFilter;
  limit_range: LimitRange;
  resource_quota: ResourceQuota;
  volume_snapshot: VolumeSnapshot;
  file_system: FileSystem;
  bundle: Bundle;
  s3_bucket: S3Bucket;
  hdfs_cluster: HdfsCluster;
  postgres_cluster: PostgresCluster;
  elasticsearch: Elasticsearch;
  redis: Redis;
  kibana: Kibana;
  zookeeper: Zookeeper;
  querybook: Querybook;
  workflow: Workflow;
  notebook: Notebook;
  ide: Ide;
  kafka: Kafka;
  kafka_connect: KafkaConnect;
  kafka_connector: KafkaConnector;
  kafka_topic: KafkaTopic;
  kafka_user: KafkaUser;
  service_monitor: ServiceMonitor;
  alertmanager: Alertmanager;
  alertmanager_config: AlertmanagerConfig;
  percona_server_mongo_db: PerconaServerMongoDb;
  percona_server_mongo_db_backup: PerconaServerMongoDbBackup;
  percona_server_mongo_db_restore: PerconaServerMongoDbRestore;
  percona_xtra_db_cluster: PerconaXtraDbCluster;
  percona_xtra_db_cluster_backup: PerconaXtraDbClusterBackup;
  percona_xtra_db_cluster_restore: PerconaXtraDbClusterRestore;

  constructor(
    id?: string,
    schema_version?: number,
    resource?: RoleResourceType,
    access?: RoleAccessType,
    persona?: RolePersonaType,
    ipa_id?: string,
    status?: RoleStatusType,
    events?: Array<ApiEvent<RoleEventType, RoleEventMetadata>>,
    account_registration?: AccountRegistration,
    resource_capacity?: ResourceCapacity,
    user?: User,
    pod?: Pod,
    deployment?: Deployment,
    stateful_set?: StatefulSet,
    daemon_set?: DaemonSet,
    replica_set?: ReplicaSet,
    job?: Job,
    service?: Service,
    persistent_volume_claim?: PersistentVolumeClaim,
    config_map?: ConfigMap,
    secret?: Secret,
    organization?: Organization,
    environment?: Environment,
    issuer?: Issuer,
    certificate?: Certificate,
    network_policy?: NetworkPolicy,
    cilium_network_policy?: CiliumNetworkPolicy,
    gateway?: Gateway,
    virtual_service?: VirtualService,
    destination_rule?: DestinationRule,
    envoy_filter?: EnvoyFilter,
    limit_range?: LimitRange,
    resource_quota?: ResourceQuota,
    volume_snapshot?: VolumeSnapshot,
    file_system?: FileSystem,
    bundle?: Bundle,
    s3_bucket?: S3Bucket,
    hdfs_cluster?: HdfsCluster,
    postgres_cluster?: PostgresCluster,
    elasticsearch?: Elasticsearch,
    redis?: Redis,
    kibana?: Kibana,
    zookeeper?: Zookeeper,
    querybook?: Querybook,
    workflow?: Workflow,
    notebook?: Notebook,
    ide?: Ide,
    kafka?: Kafka,
    kafka_connect?: KafkaConnect,
    kafka_connector?: KafkaConnector,
    kafka_topic?: KafkaTopic,
    kafka_user?: KafkaUser,
    service_monitor?: ServiceMonitor,
    alertmanager?: Alertmanager,
    alertmanager_config?: AlertmanagerConfig,
    percona_server_mongo_db?: PerconaServerMongoDb,
    percona_server_mongo_db_backup?: PerconaServerMongoDbBackup,
    percona_server_mongo_db_restore?: PerconaServerMongoDbRestore,
    percona_xtra_db_cluster?: PerconaXtraDbCluster,
    percona_xtra_db_cluster_backup?: PerconaXtraDbClusterBackup,
    percona_xtra_db_cluster_restore?: PerconaXtraDbClusterRestore
  ) {
    super(id, schema_version);

    this.resource = resource;
    this.access = access;
    this.persona = persona;
    this.ipa_id = ipa_id;
    this.status = status;
    this.events = events;
    this.account_registration = account_registration;
    this.resource_capacity = resource_capacity;
    this.user = user;
    this.pod = pod;
    this.deployment = deployment;
    this.stateful_set = stateful_set;
    this.daemon_set = daemon_set;
    this.replica_set = replica_set;
    this.job = job;
    this.service = service;
    this.persistent_volume_claim = persistent_volume_claim;
    this.config_map = config_map;
    this.secret = secret;
    this.organization = organization;
    this.environment = environment;
    this.network_policy = network_policy;
    this.cilium_network_policy = cilium_network_policy;
    this.gateway = gateway;
    this.virtual_service = virtual_service;
    this.destination_rule = destination_rule;
    this.issuer = issuer;
    this.certificate = certificate;
    this.limit_range = limit_range;
    this.resource_quota = resource_quota;
    this.volume_snapshot = volume_snapshot;
    this.file_system = file_system;
    this.bundle = bundle;
    this.s3_bucket = s3_bucket;
    this.hdfs_cluster = hdfs_cluster;
    this.postgres_cluster = postgres_cluster;
    this.elasticsearch = elasticsearch;
    this.redis = redis;
    this.kibana = kibana;
    this.zookeeper = zookeeper;
    this.querybook = querybook;
    this.workflow = workflow;
    this.notebook = notebook;
    this.ide = ide;
    this.kafka = kafka;
    this.kafka_connect = kafka_connect;
    this.kafka_connector = kafka_connector;
    this.kafka_topic = kafka_topic;
    this.kafka_user = kafka_user;
    this.service_monitor = service_monitor;
    this.alertmanager = alertmanager;
    this.alertmanager_config = alertmanager_config;
    this.percona_server_mongo_db = percona_server_mongo_db;
    this.percona_server_mongo_db_backup = percona_server_mongo_db_backup;
    this.percona_server_mongo_db_restore = percona_server_mongo_db_restore;
    this.percona_xtra_db_cluster = percona_xtra_db_cluster;
    this.percona_xtra_db_cluster_backup = percona_xtra_db_cluster_backup;
    this.percona_xtra_db_cluster_restore = percona_xtra_db_cluster_restore;
  }
}
