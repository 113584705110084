import { ElementRef, Injectable } from '@angular/core';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  private el: ElementRef;
  private stopped: boolean;

  /**
   * Service constructor
   *
   * @param animationBuilder: AnimationBuilder
   */
  constructor(private animationBuilder: AnimationBuilder) {}

  /**
   * Init
   *
   * @param element: ElementRef
   */
  init(element: ElementRef) {
    this.el = element;
  }

  /**
   * Hide
   */
  hide(delay: number) {
    if (this.stopped || !this.el) {
      return;
    } else {
      setTimeout(() => {
        const player = this.animationBuilder
          .build([
            style({ opacity: '1' }),
            animate(800, style({ opacity: '0' })),
          ])
          .create(this.el.nativeElement);

        player.onDone(() => {
          if (typeof this.el.nativeElement.remove === 'function') {
            this.el.nativeElement.remove();
            document.body.style.overflow = 'auto';
          } else {
            this.el.nativeElement.style.display = 'none !important';
          }
          this.stopped = true;
        });

        setTimeout(() => player.play(), 1500);
      }, delay);
    }
  }
}
