import { User } from './user.model';
import { Node } from './node.model';

export enum ApiStatusType {
  CREATED = 'CREATED',
  ARCHIVED = 'ARCHIVED',
}

export enum ApiEventType {
  STATUS_MODIFIED = 'STATUS_MODIFIED',
  ROLE_CREATED = 'ROLE_CREATED',
  ROLE_ATTACHED = 'ROLE_ATTACHED',
}

export interface ApiEvent<T, M> {
  _id?: string;
  node: Node;
  type: T;
  timestamp: Date;
  user?: User;
  metadata?: M;
}

export class ApiModel {
  _id: string;
  schema_version: number;

  constructor(_id: string, schema_version: number) {
    this._id = _id;
    this.schema_version = schema_version;
  }
}
