import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InitService {
  constructor(
    private configService: ConfigService,
    private authService: AuthService
  ) {}

  async init() {
    const config = await this.configService.loadConfig();
    await this.authService.initKeycloak(config);
  }
}
