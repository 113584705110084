import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ConfigActions from '../../store/actions/config.actions';
import { EndpointService } from '../../services/endpoint/endpoint.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private endpointService: EndpointService, private store: Store) {}

  async loadConfig() {
    const response = await firstValueFrom(this.endpointService.config());
    if (response.success) {
      this.store.dispatch(
        ConfigActions.load({
          version: response.data.version,
          development: response.data.development,
          env: response.data.env,
          realm: response.data.realm,
          client: response.data.client,
          hostname: response.data.hostname,
          domain: response.data.domain,
          port: response.data.port,
          mock: response.data.mock,
        })
      );
      return response.data;
    }
    return null;
  }
}
