import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { EMPTY, of, tap } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as UserActions from '../actions/user.actions';
import { EndpointService } from '../../services/endpoint/endpoint.service';
import { Router } from '@angular/router';

@Injectable()
export class UserEffects {
  setActiveOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setActiveOrganization),
      mergeMap((action) =>
        this.endpointService.userSetActiveOrganization(action).pipe(
          mergeMap((response) =>
            of(UserActions.setActiveOrganizationResponse(response))
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  setActiveEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setActiveEnvironment),
      mergeMap((action) =>
        this.endpointService.userSetActiveEnvironment(action).pipe(
          mergeMap((response) =>
            of(UserActions.setActiveEnvironmentResponse(response))
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  unsetActiveOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.unsetActiveOrganization),
        tap(() => {
          this.router.navigate(['/dashboard']);
        })
      ),
    { dispatch: false }
  );

  unsetActiveEnvironment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.unsetActiveEnvironment),
        tap(() => {
          this.router.navigate(['/dashboard']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private endpointService: EndpointService,
    private router: Router
  ) {}
}
