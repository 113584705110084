import { createAction, props } from '@ngrx/store';

import { UserResponse } from '../../models/api/response/users/user-response.model';
import { Role } from '../../models/api/role.model';
import { ApiResponse } from '../../models/api/api-response.model';
import { Organization } from '../../models/api/organization.model';
import { Environment } from '../../models/api/environment.model';
import { ClusterService } from '../../models/api/cluster-service.model';
import { UserSetActiveOrganizationResponse } from '../../models/api/response/users/user-set-active-organization-response.model';
import { UserSetActiveEnvironmentResponse } from '../../models/api/response/users/user-set-active-environment-response.model';
import { FileSystem } from '../../models/api/file-system.model';

export const load = createAction('[UserAction] Load', props<UserResponse>());

export const addRole = createAction('[UserAction] Add Role', props<Role>());

export const updateRole = createAction(
  '[UserAction] Update Role',
  props<Role>()
);

export const removeRole = createAction(
  '[UserAction] Remove Role',
  props<Role>()
);

export const setActiveOrganization = createAction(
  '[UserAction] Set Active Organization',
  props<Organization>()
);

export const unsetActiveOrganization = createAction(
  '[UserAction] Unset Active Organization',
  props<Organization>()
);

export const setActiveEnvironment = createAction(
  '[UserAction] Set Active Environment',
  props<Environment>()
);

export const unsetActiveEnvironment = createAction(
  '[UserAction] Unset Active Environment',
  props<Environment>()
);

export const setActiveClusterService = createAction(
  '[UserAction] Set Active Cluster Service',
  props<ClusterService>()
);

export const clearActiveClusterService = createAction(
  '[UserAction] Clear Active Cluster Service'
);

export const attachFileSystem = createAction(
  '[UserAction] Attach File System',
  props<FileSystem>()
);

export const detachFileSystem = createAction(
  '[UserAction] Detach File System',
  props<FileSystem>()
);

export const setActiveOrganizationResponse = createAction(
  '[UserAction] Set Active Organization Response',
  props<ApiResponse<UserSetActiveOrganizationResponse>>()
);

export const setActiveEnvironmentResponse = createAction(
  '[UserAction] Set Active Environment Response',
  props<ApiResponse<UserSetActiveEnvironmentResponse>>()
);
