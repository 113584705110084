import { createAction, props } from '@ngrx/store';

import { MenuItem } from '../../models/menu.model';

export const clearMenu = createAction('[MenuAction] Clear Menu');

export const addMenuItem = createAction(
  '[MenuAction] Add Menu Item',
  props<{ item: MenuItem }>()
);

export const addMenuItems = createAction(
  '[MenuAction] Add Menu Items',
  props<{ items: MenuItem[] }>()
);
