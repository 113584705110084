import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class DocumentService {
  constructor(@Inject(DOCUMENT) private _document: any) {}

  get nativeDocument(): any {
    return this._document;
  }
}
