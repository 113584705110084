import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MqttState, MqttTopic } from '../reducers/mqtt.reducers';
import { MqttStatusType, MqttTopicStatusType } from '../../models/mqtt.model';

export const selectMqttState = createFeatureSelector<MqttState>('mqtt');

export const mqttIsConnected = createSelector(selectMqttState, (mqtt) => {
  return mqtt.status === MqttStatusType.CONNECTED;
});

export const selectSubscribedTopics = createSelector(
  selectMqttState,
  (mqtt) => {
    const topics: { [key: string]: MqttTopic } = {};

    for (const topic in mqtt.topics) {
      if (mqtt.topics[topic].status === MqttTopicStatusType.SUBSCRIBED) {
        topics[topic] = mqtt.topics[topic];
      }
    }

    return topics;
  }
);
