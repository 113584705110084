import { createFeatureSelector, createSelector } from '@ngrx/store';
import jwt_decode from 'jwt-decode';

import { AuthState } from '../reducers/auth.reducers';
import { DecodedKeycloakJwtToken } from '../../models/api/auth/tokens.model';
import { RolePlatformType } from '../../models/api/role.model';
import { KeycloakEventType } from 'keycloak-angular';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatus = createSelector(
  selectAuthState,
  (auth): KeycloakEventType => {
    return auth.status;
  }
);

export const isLoading = createSelector(selectAuthState, (auth) => {
  return !!auth.jwt;
});

export const isLoggedIn = createSelector(selectAuthState, (auth) => {
  return (
    !!auth &&
    (auth.status === KeycloakEventType.OnReady ||
      auth.status === KeycloakEventType.OnTokenExpired ||
      auth.status === KeycloakEventType.OnAuthRefreshSuccess) &&
    !!auth.jwt
  );
});

export const isLoggedOut = createSelector(isLoggedIn, (loggedIn) => !loggedIn);

export const isPlatformOwner = createSelector(
  isLoggedIn,
  selectAuthState,
  (isLoggedIn: boolean, auth: AuthState): boolean => {
    if (isLoggedIn) {
      const decodedToken = jwt_decode(auth.jwt) as DecodedKeycloakJwtToken;
      return decodedToken.realm_access?.roles.includes('PLATFORM_OWNER');
    } else {
      return false;
    }
  }
);

export const isPlatformAdmin = createSelector(
  isLoggedIn,
  selectAuthState,
  (isLoggedIn: boolean, auth: AuthState): boolean => {
    if (isLoggedIn) {
      const decodedToken = jwt_decode(auth.jwt) as DecodedKeycloakJwtToken;
      return decodedToken.realm_access?.roles.includes('PLATFORM_ADMIN');
    } else {
      return false;
    }
  }
);

export const isPlatformUser = createSelector(
  isLoggedIn,
  selectAuthState,
  (isLoggedIn: boolean, auth: AuthState): boolean => {
    if (isLoggedIn) {
      const decodedToken = jwt_decode(auth.jwt) as DecodedKeycloakJwtToken;
      return decodedToken.realm_access?.roles.includes('PLATFORM_USER');
    } else {
      return false;
    }
  }
);

export const selectPlatformRole = createSelector(
  isLoggedIn,
  isPlatformOwner,
  isPlatformAdmin,
  isPlatformUser,
  (
    isLoggedIn: boolean,
    isPlatformOwner: boolean,
    isPlatformAdmin: boolean,
    isPlatformUser: boolean
  ): RolePlatformType => {
    if (isLoggedIn) {
      if (isPlatformOwner) {
        return RolePlatformType.PLATFORM_OWNER;
      } else if (isPlatformAdmin) {
        return RolePlatformType.PLATFORM_ADMIN;
      } else if (isPlatformUser) {
        return RolePlatformType.PLATFORM_USER;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
);
