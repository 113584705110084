import { createReducer, on } from '@ngrx/store';
import { MqttActions } from '../actions';
import { MqttStatusType, MqttTopicStatusType } from '../../models/mqtt.model';

export interface MqttTopic {
  status: MqttTopicStatusType;
  title: string;
  description: string;
  icon: string;
  messages: number;
}

export interface MqttState {
  status: MqttStatusType;
  topics: { [key: string]: MqttTopic };
}

export const initialMqttState: MqttState = {
  status: undefined,
  topics: {},
};

export const mqttReducer = createReducer(
  initialMqttState,

  on(MqttActions.updateStatus, (state, action) => {
    return {
      ...state,
      status: action.status,
    };
  }),

  on(MqttActions.createTopic, (state, action) => {
    return {
      ...state,
      topics: {
        ...state.topics,
        [action.payload.topic]: {
          status: action.status,
          title: action.payload.title,
          description: action.payload.description,
          icon: action.payload.icon,
          messages: 0,
        },
      },
    };
  }),

  on(MqttActions.updateTopicStatus, (state, action) => {
    return {
      ...state,
      topics: {
        ...state.topics,
        [action.topic]: {
          ...state.topics[action.topic],
          status: action.status,
        },
      },
    };
  }),

  on(MqttActions.topicMessageReceived, (state, action) => {
    return {
      ...state,
      topics: {
        ...state.topics,
        [action.topic]: {
          ...state.topics[action.topic],
          messages: state.topics[action.topic].messages + 1,
        },
      },
    };
  }),

  on(MqttActions.unsubscribeTopic, (state, action) => {
    const { [action.topic]: removed, ...rest } = state.topics;
    return {
      ...state,
      topics: rest,
    };
  })
);
