import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { configReducer } from '../store/reducers/config.reducers';
import { registrationReducer } from './reducers/registration.reducers';
import { authReducer } from '../store/reducers/auth.reducers';
import { userReducer } from '../store/reducers/user.reducers';
import { mqttReducer } from '../store/reducers/mqtt.reducers';
import { menuReducer } from './reducers/menu.reducers';

export interface AppState {}

export const reducers: ActionReducerMap<AppState> = {
  config: configReducer,
  registration: registrationReducer,
  auth: authReducer,
  user: userReducer,
  mqtt: mqttReducer,
  menu: menuReducer,
};

export const metaReducers: MetaReducer<AppState>[] =
  false /*!environment.production*/ ? [] : [];
