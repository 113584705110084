import { createReducer, on } from '@ngrx/store';
import { ConfigActions } from '../actions';

export interface ConfigState {
  version: string;
  development: boolean;
  env: string;
  realm: string;
  client: string;
  hostname: string;
  domain: string;
  port: number;
  mock: boolean;
}

export const initialConfigState: ConfigState = {
  version: undefined,
  development: undefined,
  env: undefined,
  realm: undefined,
  client: undefined,
  hostname: undefined,
  domain: undefined,
  port: undefined,
  mock: undefined,
};

export const configReducer = createReducer(
  initialConfigState,

  on(ConfigActions.load, (state, action) => {
    return {
      ...state,
      version: action.version,
      development: action.development,
      env: action.env,
      realm: action.realm,
      client: action.client,
      hostname: action.hostname,
      domain: action.domain,
      port: action.port,
      mock: action.mock,
    };
  })
);
