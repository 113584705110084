import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from '../reducers/user.reducers';
import { Organization } from '../../models/api/organization.model';
import {
  Role,
  RoleAccessType,
  RoleResourceType,
  RoleStatusType,
} from '../../models/api/role.model';
import { Environment } from '../../models/api/environment.model';
import { ClusterService } from '../../models/api/cluster-service.model';

export const selectUserState = createFeatureSelector<UserState>('user');

export const userLoaded = createSelector(selectUserState, (user): boolean => {
  return !!user && !!user._id;
});

export const selectActiveOrganization = createSelector(
  userLoaded,
  selectUserState,
  (userLoaded: boolean, user: UserState): Organization => {
    if (userLoaded) {
      return user.active_organization;
    } else {
      return null;
    }
  }
);

export const selectActiveEnvironment = createSelector(
  userLoaded,
  selectUserState,
  (userLoaded: boolean, user: UserState): Environment => {
    if (userLoaded) {
      return user.active_environment;
    } else {
      return null;
    }
  }
);

export const selectActiveClusterService = createSelector(
  userLoaded,
  selectUserState,
  (userLoaded: boolean, user: UserState): ClusterService => {
    if (userLoaded) {
      return user.active_cluster_service;
    } else {
      return null;
    }
  }
);

export const selectActiveOrganizationRole = createSelector(
  userLoaded,
  selectUserState,
  selectActiveOrganization,
  (
    userLoaded: boolean,
    user: UserState,
    activeOrganization: Organization
  ): Role => {
    if (userLoaded) {
      for (const role of user.roles) {
        if (
          activeOrganization &&
          role.resource === RoleResourceType.ORGANIZATION &&
          role.status === RoleStatusType.ENABLED &&
          role.organization &&
          role.organization._id === activeOrganization._id
        ) {
          return role;
        }
      }

      return null;
    } else {
      return null;
    }
  }
);

export const selectActiveEnvironmentRole = createSelector(
  userLoaded,
  selectUserState,
  selectActiveEnvironment,
  (
    userLoaded: boolean,
    user: UserState,
    activeEnvironment: Environment
  ): Role => {
    if (userLoaded) {
      for (const role of user.roles) {
        if (
          role.resource === RoleResourceType.ENVIRONMENT &&
          role.status === RoleStatusType.ENABLED &&
          activeEnvironment &&
          role.environment._id === activeEnvironment._id
        ) {
          return role;
        }
      }

      return null;
    } else {
      return null;
    }
  }
);

export const isActiveOrganizationOwner = createSelector(
  userLoaded,
  selectActiveOrganizationRole,
  (userLoaded: boolean, role: Role): boolean => {
    if (userLoaded && !!role) {
      return role.access === RoleAccessType.OWNER;
    } else {
      return false;
    }
  }
);

export const isActiveOrganizationAdmin = createSelector(
  userLoaded,
  selectActiveOrganizationRole,
  (userLoaded: boolean, role: Role): boolean => {
    if (userLoaded && !!role) {
      return role.access === RoleAccessType.ADMIN;
    } else {
      return false;
    }
  }
);

export const isActiveOrganizationUser = createSelector(
  userLoaded,
  selectActiveOrganizationRole,
  (userLoaded: boolean, role: Role): boolean => {
    if (userLoaded && !!role) {
      return role.access === RoleAccessType.USER;
    } else {
      return false;
    }
  }
);

export const isActiveEnvironmentOwner = createSelector(
  userLoaded,
  selectActiveEnvironmentRole,
  (userLoaded: boolean, role: Role): boolean => {
    if (userLoaded && !!role) {
      return role.access === RoleAccessType.OWNER;
    } else {
      return false;
    }
  }
);

export const isActiveEnvironmentAdmin = createSelector(
  userLoaded,
  selectActiveEnvironmentRole,
  (userLoaded: boolean, role: Role): boolean => {
    if (userLoaded && !!role) {
      return role.access === RoleAccessType.ADMIN;
    } else {
      return false;
    }
  }
);

export const isActiveEnvironmentUser = createSelector(
  userLoaded,
  selectActiveEnvironmentRole,
  (userLoaded: boolean, role: Role): boolean => {
    if (userLoaded && !!role) {
      return role.access === RoleAccessType.USER;
    } else {
      return false;
    }
  }
);

export const selectApprovedOrganizations = createSelector(
  userLoaded,
  selectUserState,
  (userLoaded: boolean, user: UserState): Array<Organization> => {
    const organizations: Array<Organization> = new Array<Organization>();

    if (userLoaded) {
      for (const role of user.roles) {
        if (
          role.resource === RoleResourceType.ORGANIZATION &&
          role.status === RoleStatusType.ENABLED
        ) {
          organizations.push(role.organization);
        }
      }
    }

    return organizations;
  }
);

export const selectActiveOrganizationApprovedEnvironments = createSelector(
  userLoaded,
  selectUserState,
  selectActiveOrganization,
  (
    userLoaded: boolean,
    user: UserState,
    activeOrganization: Organization
  ): Array<Environment> => {
    const environments: Array<Environment> = new Array<Environment>();

    if (userLoaded && activeOrganization) {
      for (const role of user.roles) {
        if (
          role.resource === RoleResourceType.ENVIRONMENT &&
          role.status === RoleStatusType.ENABLED &&
          typeof role.environment === 'object' &&
          role.environment.namespace.startsWith('env-') &&
          typeof activeOrganization.base_environment === 'object' &&
          !role.environment.is_base_environment &&
          ((typeof role.environment.organization === 'object' &&
            role.environment.organization._id === activeOrganization._id) ||
            (typeof role.environment.organization === 'string' &&
              role.environment.organization === activeOrganization._id))
        ) {
          environments.push(role.environment);
        }
      }
    }

    return environments;
  }
);
