import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';

import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { combineLatest, Subscription, take } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './services/auth/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from './store/selectors/auth.selectors';
import { filter } from 'rxjs/operators';
import { userLoaded } from './store/selectors/user.selectors';
import { UserService } from './services/user/user.service';
import {
  invalidRegistrationToken,
  registrationLoaded,
} from './store/selectors/registration.selectors';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Array<Subscription> = [];

  lastPing?: Date = null;
  timeout = 5;

  @ViewChild('timeout_modal', { read: TemplateRef })
  modal: TemplateRef<NgbModal> = null;

  modalRef: NgbModalRef = null;

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private store: Store,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    const routerEvents$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerEvents$);

    const isLoggedIn$ = this.store
      .pipe(
        select(isLoggedIn),
        filter((isLoggedIn: boolean) => isLoggedIn === true),
        take(1)
      )
      .subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.userService.loadUser();
        }
      });
    this.unsubscribe.push(isLoggedIn$);

    const onLoaded$ = combineLatest([
      this.store.select(invalidRegistrationToken),
      this.store.select(registrationLoaded),
      this.store.select(userLoaded),
    ])
      .pipe(
        filter(
          ([invalidRegistrationToken, isRegistrationLoaded, isUserLoaded]) =>
            invalidRegistrationToken || isRegistrationLoaded || isUserLoaded
        ),
        take(1)
      )
      .subscribe(
        ([invalidRegistrationToken, isRegistrationLoaded, isUserLoaded]) => {
          if (
            invalidRegistrationToken ||
            isRegistrationLoaded ||
            isUserLoaded
          ) {
            this.splashScreenService.hide(1500);

            if (!this.idle.isRunning()) {
              this.idle.watch();
            }
          }
        }
      );
    this.unsubscribe.push(onLoaded$);

    this.idle.setIdle(840);
    this.idle.setTimeout(60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    const onIdleEnd$ = this.idle.onIdleEnd.subscribe(() => {
      this.idle.watch();
      this.closeModal();
    });
    this.unsubscribe.push(onIdleEnd$);

    const onIdleTimeout$ = this.idle.onTimeout.subscribe(async () => {
      await this.authService.logout();
      this.closeModal();
    });
    this.unsubscribe.push(onIdleTimeout$);

    const onIdleStart$ = this.idle.onIdleStart.subscribe(() => {
      this.modalRef = this.modalService.open(this.modal, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      });
    });
    this.unsubscribe.push(onIdleStart$);

    const onIdleTimeoutWarning$ = this.idle.onTimeoutWarning.subscribe(
      (countdown) => {
        this.timeout = countdown;
      }
    );
    this.unsubscribe.push(onIdleTimeoutWarning$);

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  closeModal() {
    if (this.modalRef != null) {
      this.modalRef.close('Close click');
    }
  }
}
