import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { NgxMaskModule } from 'ngx-mask';
import { MqttModule } from 'ngx-mqtt';
import { reducers, metaReducers } from './store/reducers';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { KeycloakAngularModule } from 'keycloak-angular';
import { InitService } from './services/init/init.service';
import { MqttEffects } from './store/effects/mqtt.effects';
import { WindowService } from './services/window/window.service';
import { DocumentService } from './services/document/document.service';
import { NgxEchartsModule } from 'ngx-echarts';

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
    { name: 'json', func: json },
  ];
}

import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { TagifyModule } from 'ngx-tagify';
import { UserEffects } from './store/effects/user.effects';
import { AuthEffects } from './store/effects/auth.effects';
import { ConfigEffects } from './store/effects/config.effects';
import { MenuEffects } from './store/effects/menu.effects';
import { RegistrationEffects } from './store/effects/registration.effects';
echarts.use([SVGRenderer]);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    KeycloakAngularModule,
    NgIdleKeepaliveModule.forRoot(),
    MqttModule.forRoot({
      connectOnCreate: false,
    }),
    NgxMaskModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AuthEffects,
      ConfigEffects,
      MenuEffects,
      MqttEffects,
      UserEffects,
      RegistrationEffects,
      UserEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 500,
      logOnly: false,
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-fixed',
    }),
    TagifyModule.forRoot(),
  ],
  providers: [
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: InitService) => () => initService.init(),
      deps: [InitService],
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
    WindowService,
    DocumentService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
