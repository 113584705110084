import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { Buffer } from 'buffer';

import { select, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs/internal/observable/empty';

import { ConstantsService } from '../constants/constants';
import { ApiResponse } from '../../models/api/api-response.model';

/**
 * Files
 */
import { FileCreateRequest } from '../../models/api/request/files/file-create-request.model';
import { FileCreateResponse } from '../../models/api/response/files/file-create-response.model';
import { ChunkCreateResponse } from '../../models/api/response/chunks/chunk-create-response.model';

/**
 * Notebooks
 */
import { NotebooksResponse } from '../../models/api/response/notebooks/notebooks-response.model';
import { NotebookBuildRequest } from '../../models/api/request/notebooks/notebook-build-request.model';
import { NotebookBuildResponse } from '../../models/api/response/notebooks/notebook-build-response.model';
import { NotebookStartRequest } from '../../models/api/request/notebooks/notebook-start-request.model';
import { NotebookStartResponse } from '../../models/api/response/notebooks/notebook-start-response.model';
import { NotebookStopResponse } from '../../models/api/response/notebooks/notebook-stop-response.model';
import { NotebookDeleteResponse } from '../../models/api/response/notebooks/notebook-delete-response.model';
import { NotebookLogsResponse } from '../../models/api/response/notebooks/notebook-logs-response.model';

/**
 * IDEs
 */
import { IdesResponse } from '../../models/api/response/ides/ides-response.model';
import { IdeBuildRequest } from '../../models/api/request/ides/ide-build-request.model';
import { IdeBuildResponse } from '../../models/api/response/ides/ide-build-response.model';
import { IdeStartRequest } from '../../models/api/request/ides/ide-start-request.model';
import { IdeStartResponse } from '../../models/api/response/ides/ide-start-response.model';
import { IdeStopResponse } from '../../models/api/response/ides/ide-stop-response.model';
import { IdeLogsResponse } from '../../models/api/response/ides/ide-logs-response.model';
import { IdeDeleteResponse } from '../../models/api/response/ides/ide-delete-response.model';

/**
 * Account Registration
 */
import { AccountRegistrationDetailsResponse } from '../../models/api/response/accounts/account-registration-details-response.model';
import { AccountRegistrationSendPhoneCodeRequest } from '../../models/api/request/accounts/account-registration-send-phone-code-request.model';
import { AccountRegistrationSendPhoneCodeResponse } from '../../models/api/response/accounts/account-registration-send-phone-code-response.model';
import { AccountRegistrationVerifyPhoneCodeRequest } from '../../models/api/request/accounts/account-registration-verify-phone-code-request.model';
import { AccountRegistrationVerifyPhoneCodeResponse } from '../../models/api/response/accounts/account-registration-verify-phone-code-response.model';
import { AccountRegistrationSendEmailCodeRequest } from '../../models/api/request/accounts/account-registration-send-email-code-request.model';
import { AccountRegistrationSendEmailCodeResponse } from '../../models/api/response/accounts/account-registration-send-email-code-response.model';
import { AccountRegistrationVerifyEmailCodeRequest } from '../../models/api/request/accounts/account-registration-verify-email-code-request.model';
import { AccountRegistrationVerifyEmailCodeResponse } from '../../models/api/response/accounts/account-registration-verify-email-code-response.model';
import { AccountRegistrationSetPasswordRequest } from '../../models/api/request/accounts/account-registration-set-password-request.model';
import { AccountRegistrationSetPasswordResponse } from '../../models/api/response/accounts/account-registration-set-password-response.model';
import { AccountRegistrationGenerateOtpTokenRequest } from '../../models/api/request/accounts/account-registration-generate-otp-token-request.model';
import { AccountRegistrationGenerateOtpTokenResponse } from '../../models/api/response/accounts/account-registration-generate-otp-token-response.model';

/**
 * Organization
 */
import { OrganizationCreateRequest } from '../../models/api/request/organizations/organization-create-request.model';
import { OrganizationCreateResponse } from '../../models/api/response/organizations/organization-create-response.model';
import { OrganizationsResponse } from '../../models/api/response/organizations/organizations-response.model';
import { OrganizationEnvironmentsResponse } from '../../models/api/response/organizations/organization-environments-response.model';
import { EnvironmentResponse } from '../../models/api/response/environments/environment-response.model';
import { Organization } from '../../models/api/organization.model';
import { UserAssignedEnvironmentsResponse } from '../../models/api/response/users/user-assigned-environments-response.model';
import { Environment } from '../../models/api/environment.model';
import { UserSetActiveEnvironmentRequest } from '../../models/api/request/users/user-set-active-environment-request.model';
import { UserSetActiveOrganizationRequest } from '../../models/api/request/users/user-set-active-organization-request.model';
import { OrganizationUserRolesResponse } from '../../models/api/response/organizations/organization-user-roles-response.model';
import { UserLogoutServicesResponse } from '../../models/api/response/users/user-logout-services-response.model';
import { UserLogoutServicesRequest } from '../../models/api/request/users/user-logout-services-request.model';
import { EnvironmentDataLakesResponse } from '../../models/api/response/environments/environment-data-lakes-response.model';
import { AccountRegistrationUsernameResponse } from '../../models/api/response/accounts/account-registration-username-response.model';
import { ClusterServiceLauncherResponse } from '../../models/api/response/cluster-services/cluster-service-launcher.model';
import { AccountRegistrationVerifyPasswordRequest } from '../../models/api/request/accounts/account-registration-verify-password-request.model';
import { AccountRegistrationVerifyPasswordResponse } from '../../models/api/response/accounts/account-registration-verify-password-response.model';
import { OrganizationDetailResponse } from '../../models/api/response/organizations/organization-detail-response.model';
import { ClusterServiceFilesystemAttachRequest } from '../../models/api/request/cluster-services/cluster-service-filesystem-attach-request.model';
import { ClusterServiceFilesystemDetachRequest } from '../../models/api/request/cluster-services/cluster-service-filesystem-detach-request.model';
import { ClusterServiceDetachFilesystemResponse } from '../../models/api/response/cluster-services/cluster-service-detach-filesystem-response.model';
import { ClusterServiceAttachFilesystemResponse } from '../../models/api/response/cluster-services/cluster-service-attach-filesystem-response.model';
import { EnvironmentUsersSearchResponse } from '../../models/api/response/environments/environment-users-search-response.model';
import { EnvironmentUserAddRequest } from '../../models/api/request/environments/environment-user-add-request.model';
import { EnvironmentUserAddResponse } from '../../models/api/response/environments/environment-user-add-response.model';
import { AccountRegistrationPhoneResponse } from '../../models/api/response/accounts/account-registration-phone-response.model';
import { AccountForgotPasswordEmailRequest } from '../../models/api/request/accounts/account-forgot-password-email-request.model';
import { AccountForgotPasswordEmailResponse } from '../../models/api/response/accounts/account-forgot-password-email-response.model';
import { AccountForgotPasswordVerifyEmailRequest } from '../../models/api/request/accounts/account-forgot-password-verify-email-request.model';
import { AccountForgotPasswordVerifyEmailResponse } from '../../models/api/response/accounts/account-forgot-password-verify-email-response.model';
import { AccountForgotPasswordSendSmsRequest } from '../../models/api/request/accounts/account-forgot-password-send-sms-request.model';
import { AccountForgotPasswordVerifySmsRequest } from '../../models/api/request/accounts/account-forgot-password-verify-sms-request.model';
import { UserSetActiveEnvironmentResponse } from '../../models/api/response/users/user-set-active-environment-response.model';
import { UserSetActiveOrganizationResponse } from '../../models/api/response/users/user-set-active-organization-response.model';
import { ClusterServiceLauncherRequest } from '../../models/api/request/cluster-services/cluster-service-launcher-request.model';
import { AccountRegistrationRegisterPivResponse } from '../../models/api/response/accounts/account-registration-register-piv-response.model';
import { AccountRegistrationRegisterPivRequest } from '../../models/api/request/accounts/account-registration-register-piv-request.model';
import { AccountRegistrationVerifyOtpTokenRequest } from '../../models/api/request/accounts/account-registration-verify-otp-token-request.model';
import { AccountRegistrationVerifyOtpTokenResponse } from '../../models/api/response/accounts/account-registration-verify-otp-token-response.model';
import { AccountRegistrationSubmitRequest } from '../../models/api/request/accounts/account-registration-submit-request.model';
import { AccountRegistrationSubmitResponse } from '../../models/api/response/accounts/account-registration-submit-response.model';
import { ConfigResponse } from '../../models/api/response/config/config-response.model';
import { UserResponse } from '../../models/api/response/users/user-response.model';
import { ClusterService } from '../../models/api/cluster-service.model';
import {
  userLoaded,
  selectActiveOrganization,
  selectActiveEnvironment,
  selectActiveClusterService,
} from '../../store/selectors/user.selectors';
import { filter } from 'rxjs/operators';
import { EnvironmentCreateRequest } from '../../models/api/request/environments/environment-create-request.model';
import { EnvironmentCreateResponse } from '../../models/api/response/environments/environment-create-response.model';
import { EnvironmentArchiveResponse } from '../../models/api/response/environments/environment-archive-response.model';
import { OrganizationCapacityUsersAvailableResponse } from '../../models/api/response/organizations/organization-capacity-users-available-response.model';
import { User } from '../../models/api/user.model';
import { OrganizationAddUsersRequest } from '../../models/api/request/organizations/organization-add-users-request.model';
import { OrganizationAddUsersResponse } from '../../models/api/response/organizations/organization-add-users-response.model';
import { UserOrganizationAccountRegistrationResponse } from '../../models/api/response/users/user-organization-account-registration-response.model';
import { OrganizationApproveUserResponse } from '../../models/api/response/organizations/organization-approve-user-response.model';
import { OrganizationCapacityUsersUtilizedResponse } from '../../models/api/response/organizations/organization-capacity-users-utilized-response.model';
import { OrganizationCapacityResourcesUtilizedResponse } from '../../models/api/response/organizations/organization-capacity-resources-utilized-response.model';
import { OrganizationCapacityEnvironmentsUtilizedResponse } from '../../models/api/response/organizations/organization-capacity-environments-utilized-response.model';
import { EnvironmentCapacityUsersUtilizedResponse } from '../../models/api/response/environments/environment-capacity-users-utilized-response.model';
import { EnvironmentAddUsersRequest } from '../../models/api/request/environments/environment-users-add-request.model';
import { EnvironmentUserRolesResponse } from '../../models/api/response/environments/environment-user-roles-response.model';
import { EnvironmentClusterServicesResponse } from '../../models/api/response/environments/environment-cluster-services-response.model';
import { Notebook } from '../../models/api/notebook.model';
import { Ide } from '../../models/api/ide.model';
import { OrganizationArchiveResponse } from '../../models/api/response/organizations/organization-archive-response.model';
import { EnvironmentUserRemoveResponse } from '../../models/api/response/environments/environment-user-remove-response.model';
import { EnvironmentUserDisableResponse } from '../../models/api/response/environments/environment-user-disable-response.model';
import { EnvironmentUserEnableResponse } from '../../models/api/response/environments/environment-user-enable-response.model';
import { OrganizationUserDisableResponse } from '../../models/api/response/organizations/organization-user-disable-response.model';
import { OrganizationUserEnableResponse } from '../../models/api/response/organizations/organization-user-enable-response.model';
import { OrganizationUserRemoveResponse } from '../../models/api/response/organizations/organization-user-remove-response.model';

@Injectable({
  providedIn: 'root',
})
export class EndpointService {
  activeOrganization: Organization = null;
  activeEnvironment: Environment = null;
  activeClusterService: ClusterService = null;

  constructor(
    private http: HttpClient,
    private store: Store,
    private constants: ConstantsService
  ) {
    combineLatest([
      this.store.pipe(select(userLoaded)),
      this.store.pipe(select(selectActiveOrganization)),
      this.store.pipe(select(selectActiveEnvironment)),
      this.store.pipe(select(selectActiveClusterService)),
    ])
      .pipe(filter(([userLoaded]) => userLoaded === true))
      .subscribe(
        ([
          userLoaded,
          activeOrganization,
          activeEnvironment,
          activeClusterService,
        ]: [boolean, Organization, Environment, ClusterService]) => {
          if (userLoaded) {
            this.activeOrganization = activeOrganization;
            this.activeEnvironment = activeEnvironment;
            this.activeClusterService = activeClusterService;
          }
        }
      );
  }

  /***
   * Config
   */
  config(): Observable<ApiResponse<ConfigResponse>> {
    return this.http.get<ApiResponse<ConfigResponse>>(
      this.constants.URL.CONFIG()
    );
  }

  /***
   * Account Registration
   */
  accountRegistrationGet(
    token: string
  ): Observable<ApiResponse<AccountRegistrationDetailsResponse>> {
    return this.http.get<ApiResponse<AccountRegistrationDetailsResponse>>(
      this.constants.URL.ACCOUNTS_REGISTRATION(token)
    );
  }

  accountRegistrationUsernameExists(
    token: string,
    username: string
  ): Observable<ApiResponse<AccountRegistrationUsernameResponse>> {
    return this.http.get<ApiResponse<AccountRegistrationUsernameResponse>>(
      this.constants.URL.ACCOUNTS_REGISTRATION_USERNAME(token),
      {
        params: {
          username,
        },
      }
    );
  }

  accountRegistrationPhoneExists(
    token: string,
    prefix: string,
    phone: string
  ): Observable<ApiResponse<AccountRegistrationPhoneResponse>> {
    return this.http.get<ApiResponse<AccountRegistrationPhoneResponse>>(
      this.constants.URL.ACCOUNTS_REGISTRATION_PHONE(token),
      {
        params: {
          prefix,
          phone,
        },
      }
    );
  }

  accountRegistrationSendEmailCode(
    token: string,
    accountRegistrationSendEmailCodeRequest: AccountRegistrationSendEmailCodeRequest
  ): Observable<ApiResponse<AccountRegistrationSendEmailCodeResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationSendEmailCodeResponse>
    >(
      this.constants.URL.ACCOUNTS_REGISTRATION_EMAIL_CODE(token),
      accountRegistrationSendEmailCodeRequest
    );
  }

  accountRegistrationVerifyEmailCode(
    token: string,
    accountRegistrationVerifyEmailCodeRequest: AccountRegistrationVerifyEmailCodeRequest
  ): Observable<ApiResponse<AccountRegistrationVerifyEmailCodeResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationVerifyEmailCodeResponse>
    >(
      this.constants.URL.ACCOUNTS_REGISTRATION_EMAIL_VERIFY(token),
      accountRegistrationVerifyEmailCodeRequest
    );
  }

  accountRegistrationSendPhoneCode(
    token: string,
    accountRegistrationSendPhoneCodeRequest: AccountRegistrationSendPhoneCodeRequest
  ): Observable<ApiResponse<AccountRegistrationSendPhoneCodeResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationSendPhoneCodeResponse>
    >(
      this.constants.URL.ACCOUNTS_REGISTRATION_PHONE_CODE(token),
      accountRegistrationSendPhoneCodeRequest
    );
  }

  accountRegistrationVerifyPhoneCode(
    token: string,
    accountRegistrationVerifyPhoneCodeRequest: AccountRegistrationVerifyPhoneCodeRequest
  ): Observable<ApiResponse<AccountRegistrationVerifyPhoneCodeResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationVerifyPhoneCodeResponse>
    >(
      this.constants.URL.ACCOUNTS_REGISTRATION_PHONE_VERIFY(token),
      accountRegistrationVerifyPhoneCodeRequest
    );
  }

  accountRegistrationSetPassword(
    token: string,
    accountRegistrationSetPasswordRequest: AccountRegistrationSetPasswordRequest
  ): Observable<ApiResponse<AccountRegistrationSetPasswordResponse>> {
    return this.http.post<ApiResponse<AccountRegistrationSetPasswordResponse>>(
      this.constants.URL.ACCOUNTS_REGISTRATION_SET_PASSWORD(token),
      accountRegistrationSetPasswordRequest
    );
  }

  accountRegistrationVerifyPassword(
    token: string,
    accountRegistrationVerifyPasswordRequest: AccountRegistrationVerifyPasswordRequest
  ): Observable<ApiResponse<AccountRegistrationVerifyPasswordResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationVerifyPasswordResponse>
    >(
      this.constants.URL.ACCOUNTS_REGISTRATION_PASSWORD_VERIFY(token),
      accountRegistrationVerifyPasswordRequest
    );
  }

  accountRegistrationGenerateOtpToken(
    token: string,
    accountRegistrationGenerateOtpTokenRequest: AccountRegistrationGenerateOtpTokenRequest
  ): Observable<ApiResponse<AccountRegistrationGenerateOtpTokenResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationGenerateOtpTokenResponse>
    >(
      this.constants.URL.ACCOUNTS_REGISTRATION_GENERATE_OTP_TOKEN(token),
      accountRegistrationGenerateOtpTokenRequest
    );
  }

  accountRegistrationVerifyOtpToken(
    token: string,
    accountRegistrationVerifyOtpTokenRequest: AccountRegistrationVerifyOtpTokenRequest
  ): Observable<ApiResponse<AccountRegistrationVerifyOtpTokenResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationVerifyOtpTokenResponse>
    >(
      this.constants.URL.ACCOUNTS_REGISTRATION_VERIFY_OTP_TOKEN(token),
      accountRegistrationVerifyOtpTokenRequest
    );
  }

  accountRegistrationRegisterPiv(
    token: string,
    accountRegistrationRegisterPivRequest: AccountRegistrationRegisterPivRequest
  ): Observable<ApiResponse<AccountRegistrationRegisterPivResponse>> {
    return this.http.post<ApiResponse<AccountRegistrationRegisterPivResponse>>(
      this.constants.URL.ACCOUNTS_REGISTRATION_REGISTER_PIV(token),
      accountRegistrationRegisterPivRequest
    );
  }

  accountRegistrationSubmit(
    token: string,
    accountRegistrationSubmitRequest: AccountRegistrationSubmitRequest
  ): Observable<ApiResponse<AccountRegistrationSubmitResponse>> {
    return this.http.post<ApiResponse<AccountRegistrationSubmitResponse>>(
      this.constants.URL.ACCOUNTS_REGISTRATION_SUBMIT(token),
      accountRegistrationSubmitRequest
    );
  }

  accountForgotPasswordSendEmail(
    accountForgotPasswordEmailRequest: AccountForgotPasswordEmailRequest
  ): Observable<ApiResponse<AccountForgotPasswordEmailResponse>> {
    return this.http.post<ApiResponse<AccountForgotPasswordEmailResponse>>(
      this.constants.URL.ACCOUNTS_FORGOT_PASSWORD_EMAIL(),
      accountForgotPasswordEmailRequest
    );
  }

  accountForgotPasswordVerifyEmail(
    accountForgotPasswordVerifyEmailRequest: AccountForgotPasswordVerifyEmailRequest
  ): Observable<ApiResponse<AccountForgotPasswordVerifyEmailResponse>> {
    return this.http.post<
      ApiResponse<AccountForgotPasswordVerifyEmailResponse>
    >(
      this.constants.URL.ACCOUNTS_FORGOT_PASSWORD_EMAIL_VERIFY(),
      accountForgotPasswordVerifyEmailRequest
    );
  }

  accountForgotPasswordSendPhoneCode(
    accountForgotPasswordSendSmsRequest: AccountForgotPasswordSendSmsRequest
  ): Observable<ApiResponse<AccountRegistrationSendPhoneCodeResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationSendPhoneCodeResponse>
    >(
      this.constants.URL.ACCOUNTS_FORGOT_PASSWORD_SMS(),
      accountForgotPasswordSendSmsRequest
    );
  }

  accountForgotPasswordVerifyPhoneCode(
    accountForgotPasswordVerifySmsRequest: AccountForgotPasswordVerifySmsRequest
  ): Observable<ApiResponse<AccountRegistrationVerifyPhoneCodeResponse>> {
    return this.http.post<
      ApiResponse<AccountRegistrationVerifyPhoneCodeResponse>
    >(
      this.constants.URL.ACCOUNTS_FORGOT_PASSWORD_SMS_VERIFY(),
      accountForgotPasswordVerifySmsRequest
    );
  }

  /***
   * User
   * @param authenticationRequest
   */
  user(): Observable<ApiResponse<UserResponse>> {
    return this.http.get<ApiResponse<UserResponse>>(this.constants.URL.USER());
  }

  userSetActiveOrganization(
    organization: Organization
  ): Observable<ApiResponse<UserSetActiveOrganizationResponse>> {
    return this.http.post<ApiResponse<UserSetActiveOrganizationResponse>>(
      this.constants.URL.USER_ACTIVE_ORGANIZATION(),
      new UserSetActiveOrganizationRequest(organization)
    );
  }

  userSetActiveEnvironment(
    environment: Environment
  ): Observable<ApiResponse<UserSetActiveEnvironmentResponse>> {
    return this.http.post<ApiResponse<UserSetActiveEnvironmentResponse>>(
      this.constants.URL.USER_ACTIVE_ENVIRONMENT(),
      new UserSetActiveEnvironmentRequest(environment)
    );
  }

  userAssignedEnvironments(
    organization: Organization
  ): Observable<ApiResponse<UserAssignedEnvironmentsResponse>> {
    return this.http.get<ApiResponse<UserAssignedEnvironmentsResponse>>(
      this.constants.URL.USER_ASSIGNED_ENVIRONMENTS(organization._id)
    );
  }

  userOrganizationRegistration(
    user: User,
    organization: Organization
  ): Observable<ApiResponse<UserOrganizationAccountRegistrationResponse>> {
    return this.http.get<
      ApiResponse<UserOrganizationAccountRegistrationResponse>
    >(this.constants.URL.USER_ORGANIZATION_REGISTRATION(user, organization));
  }

  userLogoutServices(
    userLogoutServicesRequest: UserLogoutServicesRequest
  ): Observable<ApiResponse<UserLogoutServicesResponse>> {
    return this.http.post<ApiResponse<UserLogoutServicesResponse>>(
      this.constants.URL.USER_LOGOUT_SERVICES(),
      userLogoutServicesRequest
    );
  }

  /***
   * Organization
   * @param authenticationRequest
   */
  organizationGetAll(): Observable<ApiResponse<OrganizationsResponse>> {
    return this.http.get<ApiResponse<OrganizationsResponse>>(
      this.constants.URL.ORGANIZATION()
    );
  }

  organizationCreate(
    organizationCreateRequest: OrganizationCreateRequest
  ): Observable<ApiResponse<OrganizationCreateResponse>> {
    return this.http.post<ApiResponse<OrganizationCreateResponse>>(
      this.constants.URL.ORGANIZATION(),
      organizationCreateRequest
    );
  }

  organizationGetAvailableUsersCapacity(
    organization: Organization
  ): Observable<ApiResponse<OrganizationCapacityUsersAvailableResponse>> {
    return this.http.get<
      ApiResponse<OrganizationCapacityUsersAvailableResponse>
    >(this.constants.URL.ORGANIZATION_CAPACITY_USERS_AVAILABLE(organization));
  }

  organizationGetUtilizedEnvironmentsCapacity(
    organization: Organization
  ): Observable<ApiResponse<OrganizationCapacityEnvironmentsUtilizedResponse>> {
    return this.http.get<
      ApiResponse<OrganizationCapacityEnvironmentsUtilizedResponse>
    >(
      this.constants.URL.ORGANIZATION_CAPACITY_ENVIRONMENTS_UTILIZED(
        organization
      )
    );
  }

  organizationGetUtilizedUsersCapacity(
    organization: Organization
  ): Observable<ApiResponse<OrganizationCapacityUsersUtilizedResponse>> {
    return this.http.get<
      ApiResponse<OrganizationCapacityUsersUtilizedResponse>
    >(this.constants.URL.ORGANIZATION_CAPACITY_USERS_UTILIZED(organization));
  }

  organizationGetUtilizedResourcesCapacity(
    organization: Organization
  ): Observable<ApiResponse<OrganizationCapacityResourcesUtilizedResponse>> {
    return this.http.get<
      ApiResponse<OrganizationCapacityResourcesUtilizedResponse>
    >(
      this.constants.URL.ORGANIZATION_CAPACITY_RESOURCES_UTILIZED(organization)
    );
  }

  organizationAddUsers(
    organization: Organization,
    organizationAddUsersRequest: OrganizationAddUsersRequest
  ): Observable<ApiResponse<OrganizationAddUsersResponse>> {
    return this.http.post<ApiResponse<OrganizationAddUsersResponse>>(
      this.constants.URL.ORGANIZATION_USERS(organization),
      organizationAddUsersRequest
    );
  }

  organizationDisableUser(
    organization: Organization,
    user: User
  ): Observable<ApiResponse<OrganizationUserDisableResponse>> {
    return this.http.post<ApiResponse<OrganizationUserDisableResponse>>(
      this.constants.URL.ORGANIZATION_USER_DISABLE(organization, user),
      {}
    );
  }

  organizationEnableUser(
    organization: Organization,
    user: User
  ): Observable<ApiResponse<OrganizationUserEnableResponse>> {
    return this.http.post<ApiResponse<OrganizationUserEnableResponse>>(
      this.constants.URL.ORGANIZATION_USER_ENABLE(organization, user),
      {}
    );
  }

  organizationRemoveUser(
    organization: Organization,
    user: User
  ): Observable<ApiResponse<OrganizationUserRemoveResponse>> {
    return this.http.delete<ApiResponse<OrganizationUserRemoveResponse>>(
      this.constants.URL.ORGANIZATION_USER(organization, user)
    );
  }

  organizationActive(): Observable<ApiResponse<OrganizationDetailResponse>> {
    return this.http.get<ApiResponse<OrganizationDetailResponse>>(
      this.constants.URL.ORGANIZATION(this.activeOrganization)
    );
  }

  organizationUserApprove(
    organization: Organization,
    user: User
  ): Observable<ApiResponse<OrganizationApproveUserResponse>> {
    return this.http.post<ApiResponse<OrganizationApproveUserResponse>>(
      this.constants.URL.ORGANIZATION_USER_APPROVE(organization, user),
      null
    );
  }

  organizationUserReject(
    organization: Organization,
    user: User
  ): Observable<ApiResponse<OrganizationApproveUserResponse>> {
    return this.http.post<ApiResponse<OrganizationApproveUserResponse>>(
      this.constants.URL.ORGANIZATION_USER_REJECT(organization, user),
      null
    );
  }

  organizationUserRoles(
    organization: Organization
  ): Observable<ApiResponse<OrganizationUserRolesResponse>> {
    return this.http.get<ApiResponse<OrganizationUserRolesResponse>>(
      this.constants.URL.ORGANIZATION_USER_ROLES(organization)
    );
  }

  organizationActiveGetAllEnvironments(): Observable<
    ApiResponse<OrganizationEnvironmentsResponse>
  > {
    return this.http.get<ApiResponse<OrganizationEnvironmentsResponse>>(
      this.constants.URL.ORGANIZATION_ENVIRONMENTS(this.activeOrganization?._id)
    );
  }

  organizationArchive(
    organization: Organization
  ): Observable<ApiResponse<OrganizationArchiveResponse>> {
    return this.http.delete<ApiResponse<OrganizationArchiveResponse>>(
      this.constants.URL.ORGANIZATION(organization)
    );
  }

  /***
   * Environment
   * @param authenticationRequest
   */
  environment(
    environment: Environment
  ): Observable<ApiResponse<EnvironmentResponse>> {
    return this.http.get<ApiResponse<EnvironmentResponse>>(
      this.constants.URL.ENVIRONMENT(environment)
    );
  }

  environmentClusterServices(
    environment: Environment
  ): Observable<ApiResponse<EnvironmentClusterServicesResponse>> {
    return this.http.get<ApiResponse<EnvironmentClusterServicesResponse>>(
      this.constants.URL.ENVIRONMENT_CLUSTER_SERVICES(environment)
    );
  }

  environmentGetAvailableUsersCapacity(
    environment: Environment
  ): Observable<ApiResponse<OrganizationCapacityUsersAvailableResponse>> {
    return this.http.get<
      ApiResponse<OrganizationCapacityUsersAvailableResponse>
    >(this.constants.URL.ENVIRONMENT_CAPACITY_USERS_AVAILABLE(environment));
  }

  environmentCreate(
    organization: Organization,
    environmentCreateRequest: EnvironmentCreateRequest
  ): Observable<ApiResponse<EnvironmentCreateResponse>> {
    return this.http.post<ApiResponse<EnvironmentCreateResponse>>(
      this.constants.URL.ENVIRONMENT_CREATE(organization),
      environmentCreateRequest
    );
  }

  environmentUserRoles(
    environment: Environment
  ): Observable<ApiResponse<EnvironmentUserRolesResponse>> {
    return this.http.get<ApiResponse<EnvironmentUserRolesResponse>>(
      this.constants.URL.ENVIRONMENT_USER_ROLES(environment)
    );
  }

  environmentGetUtilizedUsersCapacity(
    environment: Environment
  ): Observable<ApiResponse<EnvironmentCapacityUsersUtilizedResponse>> {
    return this.http.get<ApiResponse<EnvironmentCapacityUsersUtilizedResponse>>(
      this.constants.URL.ENVIRONMENT_CAPACITY_USERS_UTILIZED(environment)
    );
  }

  environmentAddUsers(
    environment: Environment,
    environmentAddUsersRequest: EnvironmentAddUsersRequest
  ): Observable<ApiResponse<OrganizationAddUsersResponse>> {
    return this.http.post<ApiResponse<OrganizationAddUsersResponse>>(
      this.constants.URL.ENVIRONMENT_USERS(environment),
      environmentAddUsersRequest
    );
  }

  environmentDisableUser(
    environment: Environment,
    user: User
  ): Observable<ApiResponse<EnvironmentUserDisableResponse>> {
    return this.http.post<ApiResponse<EnvironmentUserDisableResponse>>(
      this.constants.URL.ENVIRONMENT_USER_DISABLE(environment, user),
      {}
    );
  }

  environmentEnableUser(
    environment: Environment,
    user: User
  ): Observable<ApiResponse<EnvironmentUserEnableResponse>> {
    return this.http.post<ApiResponse<EnvironmentUserEnableResponse>>(
      this.constants.URL.ENVIRONMENT_USER_ENABLE(environment, user),
      {}
    );
  }

  environmentRemoveUser(
    environment: Environment,
    user: User
  ): Observable<ApiResponse<EnvironmentUserRemoveResponse>> {
    return this.http.delete<ApiResponse<EnvironmentUserRemoveResponse>>(
      this.constants.URL.ENVIRONMENT_USER(environment, user)
    );
  }

  environmentArchive(
    environment: Environment
  ): Observable<ApiResponse<EnvironmentArchiveResponse>> {
    return this.http.delete<ApiResponse<EnvironmentArchiveResponse>>(
      this.constants.URL.ENVIRONMENT(environment)
    );
  }

  environmentDataLakes(): Observable<
    ApiResponse<EnvironmentDataLakesResponse>
  > {
    return this.http.get<ApiResponse<EnvironmentDataLakesResponse>>(
      this.constants.URL.ENVIRONMENT_DATA_LAKES(this.activeEnvironment?._id)
    );
  }

  environmentUsersSearch(
    environment: Environment,
    query: string
  ): Observable<ApiResponse<EnvironmentUsersSearchResponse>> {
    return this.http.get<ApiResponse<EnvironmentUsersSearchResponse>>(
      this.constants.URL.ENVIRONMENT_USERS_SEARCH(environment),
      {
        params: {
          query,
        },
      }
    );
  }

  environmentUserAdd(
    environment: Environment,
    environmentUserAddRequest: EnvironmentUserAddRequest
  ): Observable<ApiResponse<EnvironmentUserAddResponse>> {
    return this.http.post<ApiResponse<EnvironmentUserAddResponse>>(
      this.constants.URL.ENVIRONMENT_USER(environment),
      environmentUserAddRequest
    );
  }

  environmentNotebookBuild(
    environment: Environment,
    notebookBuildRequest: NotebookBuildRequest
  ): Observable<ApiResponse<NotebookBuildResponse>> {
    return this.http.post<ApiResponse<NotebookBuildResponse>>(
      this.constants.URL.ENVIRONMENT_NOTEBOOK_BUILD(environment),
      notebookBuildRequest
    );
  }

  environmentNotebooks(
    environment: Environment
  ): Observable<ApiResponse<NotebooksResponse>> {
    return this.http.get<ApiResponse<NotebooksResponse>>(
      this.constants.URL.ENVIRONMENT_NOTEBOOKS(environment)
    );
  }

  environmentIdeBuild(
    environment: Environment,
    ideBuildRequest: IdeBuildRequest
  ): Observable<ApiResponse<IdeBuildResponse>> {
    return this.http.post<ApiResponse<IdeBuildResponse>>(
      this.constants.URL.ENVIRONMENT_IDE_BUILD(environment),
      ideBuildRequest
    );
  }

  environmentIdes(
    environment: Environment
  ): Observable<ApiResponse<IdesResponse>> {
    return this.http.get<ApiResponse<IdesResponse>>(
      this.constants.URL.ENVIRONMENT_IDES(environment)
    );
  }

  // environmentUserAdd(
  //   user: User,
  //   roleAccessLevel: RoleAccessLevelType
  // ): Observable<ApiResponse<EnvironmentUsersSearchResponse>> {
  //   return this.http.get<ApiResponse<EnvironmentUsersSearchResponse>>(
  //     this.constants.URL.ENVIRONMENT_USERS_SEARCH(environment._id),
  //     {
  //       params: {
  //         query,
  //       },
  //     }
  //   );
  // }

  /***
   * File
   * @param fileCreateRequest
   */
  fileCreate(
    fileCreateRequest: FileCreateRequest
  ): Observable<ApiResponse<FileCreateResponse>> {
    return this.http.post<ApiResponse<FileCreateResponse>>(
      this.constants.URL.FILES(),
      fileCreateRequest
    );
  }

  fileChunkCreate(
    fileSystemId: string,
    fileSha256: string,
    chunkSha256: string,
    chunkId: number,
    chunkData: Buffer
  ): Observable<ApiResponse<ChunkCreateResponse>> {
    const formData: FormData = new FormData();
    formData.set('fileSystemId', fileSystemId);
    formData.set('fileSha256', fileSha256);
    formData.set('chunkSha256', chunkSha256);
    formData.set('chunkId', chunkId.toString());
    formData.set('chunkData', new Blob([new Uint8Array(chunkData)]));

    return this.http.post<ApiResponse<ChunkCreateResponse>>(
      this.constants.URL.FILES_CHUNK(),
      formData,
      {
        reportProgress: true,
      }
    );
  }

  /***
   * Launcher
   */
  launchEnvironmentService(
    path: string
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    if (this.activeEnvironment?._id != null) {
      return this.http.post<ApiResponse<ClusterServiceLauncherResponse>>(
        path,
        null
      );
    } else {
      return EMPTY;
    }
  }

  launchOrganizationService(
    path: string,
    clusterServiceLauncherRequest: ClusterServiceLauncherRequest
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    if (this.activeOrganization?._id != null) {
      return this.http.post<ApiResponse<ClusterServiceLauncherResponse>>(
        path,
        clusterServiceLauncherRequest
      );
    } else {
      return EMPTY;
    }
  }

  launcherJitsi(): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchOrganizationService(
      this.constants.URL.LAUNCHERS_JITSI(this.activeOrganization?._id),
      null
    );
  }

  launcherMattermost(): Observable<
    ApiResponse<ClusterServiceLauncherResponse>
  > {
    return this.launchOrganizationService(
      this.constants.URL.LAUNCHERS_MATTERMOST(this.activeOrganization?._id),
      null
    );
  }

  launcherWikijs(): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchOrganizationService(
      this.constants.URL.LAUNCHERS_WIKIJS(this.activeOrganization?._id),
      null
    );
  }

  launcherOpenproject(): Observable<
    ApiResponse<ClusterServiceLauncherResponse>
  > {
    return this.launchOrganizationService(
      this.constants.URL.LAUNCHERS_OPENPROJECT(this.activeOrganization?._id),
      null
    );
  }

  launcherAirbyte(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_AIRBYTE(environment._id)
    );
  }

  launcherAirflow(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_AIRFLOW(environment._id)
    );
  }

  launcherHop(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_HOP(environment._id)
    );
  }

  launcherStreampipes(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_STREAMPIPES(environment._id)
    );
  }

  launcherPgadmin(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_PGADMIN(environment._id)
    );
  }

  launcherKibana(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_KIBANA(environment._id)
    );
  }

  launcherGrafana(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_GRAFANA(environment._id)
    );
  }

  launcherNifi(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_NIFI(environment._id)
    );
  }

  launcherNotebook(
    uuid: string
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_NOTEBOOK(this.activeEnvironment?._id, uuid)
    );
  }

  launcherQuerybook(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_QUERYBOOK(environment._id)
    );
  }

  launcherDriverlessAi(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_DRIVERLESS_AI(environment._id)
    );
  }

  launcherHue(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_HUE(environment._id)
    );
  }

  launcherOvaledge(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_OVALEDGE(environment._id)
    );
  }

  launcherIde(
    uuid: string
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_IDE(this.activeEnvironment?._id, uuid)
    );
  }

  launcherGitea(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_GITEA(environment._id)
    );
  }

  launcherArgo(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_ARGO(environment._id)
    );
  }

  launcherApiman(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_APIMAN(environment._id)
    );
  }

  launcherArchiva(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_ARCHIVA(environment._id)
    );
  }

  launcherSuperset(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_SUPERSET(environment._id)
    );
  }

  launcherEntando(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_ENTANDO(environment._id)
    );
  }

  launcherJbpm(
    environment: Environment
  ): Observable<ApiResponse<ClusterServiceLauncherResponse>> {
    return this.launchEnvironmentService(
      this.constants.URL.LAUNCHERS_JBPM(environment._id)
    );
  }

  /***
   * Notebook
   * @param authenticationRequest
   */
  notebookStart(
    notebook: Notebook,
    notebookStartRequest: NotebookStartRequest
  ): Observable<ApiResponse<NotebookStartResponse>> {
    return this.http.post<ApiResponse<NotebookStartResponse>>(
      this.constants.URL.NOTEBOOK_START(notebook),
      notebookStartRequest
    );
  }

  notebookStop(
    notebook: Notebook
  ): Observable<ApiResponse<NotebookStopResponse>> {
    return this.http.post<ApiResponse<NotebookStopResponse>>(
      this.constants.URL.NOTEBOOK_STOP(notebook),
      null
    );
  }

  notebookSubscribeLogs(
    notebook: Notebook
  ): Observable<ApiResponse<NotebookLogsResponse>> {
    return this.http.get<ApiResponse<NotebookLogsResponse>>(
      this.constants.URL.NOTEBOOK_LOGS(notebook)
    );
  }

  notebookUnsubscribeLogs(
    notebook: Notebook
  ): Observable<ApiResponse<NotebookLogsResponse>> {
    return this.http.delete<ApiResponse<NotebookLogsResponse>>(
      this.constants.URL.NOTEBOOK_LOGS(notebook)
    );
  }

  notebookArchive(
    notebook: Notebook
  ): Observable<ApiResponse<NotebookDeleteResponse>> {
    return this.http.delete<ApiResponse<NotebookDeleteResponse>>(
      this.constants.URL.NOTEBOOK(notebook)
    );
  }

  /***
   * IDE
   * @param authenticationRequest
   */
  ideStart(
    ide: Ide,
    ideStartRequest: IdeStartRequest
  ): Observable<ApiResponse<IdeStartResponse>> {
    return this.http.post<ApiResponse<IdeStartResponse>>(
      this.constants.URL.IDE_START(ide),
      ideStartRequest
    );
  }

  ideStop(ide: Ide): Observable<ApiResponse<IdeStopResponse>> {
    return this.http.post<ApiResponse<IdeStopResponse>>(
      this.constants.URL.IDE_STOP(ide),
      null
    );
  }

  ideSubscribeLogs(ide: Ide): Observable<ApiResponse<IdeLogsResponse>> {
    return this.http.get<ApiResponse<IdeLogsResponse>>(
      this.constants.URL.IDE_LOGS(ide)
    );
  }

  ideUnsubscribeLogs(ide: Ide): Observable<ApiResponse<IdeLogsResponse>> {
    return this.http.delete<ApiResponse<IdeLogsResponse>>(
      this.constants.URL.IDE_LOGS(ide)
    );
  }

  ideArchive(ide: Ide): Observable<ApiResponse<IdeDeleteResponse>> {
    return this.http.delete<ApiResponse<IdeDeleteResponse>>(
      this.constants.URL.IDE(ide)
    );
  }

  /***
   * Cluster Services
   * @param authenticationRequest
   */
  clusterServicesAttachFilesystem(
    clusterServiceAttachFilesystemRequest: ClusterServiceFilesystemAttachRequest
  ): Observable<ApiResponse<ClusterServiceAttachFilesystemResponse>> {
    return this.http.post<ApiResponse<ClusterServiceAttachFilesystemResponse>>(
      this.constants.URL.CLUSTER_SERVICE_FILESYSTEM_ATTACH(
        clusterServiceAttachFilesystemRequest.cluster_service._id
      ),
      clusterServiceAttachFilesystemRequest
    );
  }

  clusterServicesDetachFilesystem(
    clusterServiceDetachFilesystemRequest: ClusterServiceFilesystemDetachRequest
  ): Observable<ApiResponse<ClusterServiceDetachFilesystemResponse>> {
    return this.http.post<ApiResponse<ClusterServiceDetachFilesystemResponse>>(
      this.constants.URL.CLUSTER_SERVICE_FILESYSTEM_DETACH(
        clusterServiceDetachFilesystemRequest.cluster_service._id
      ),
      clusterServiceDetachFilesystemRequest
    );
  }
}
