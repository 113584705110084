import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  isPlatformAdmin,
  isPlatformOwner,
  isPlatformUser,
} from '../../store/selectors/auth.selectors';
import { UserActions } from '../../store/actions';
import { filter } from 'rxjs/operators';
import { EndpointService } from '../endpoint/endpoint.service';
import { combineLatest, take } from 'rxjs';
import {
  selectActiveEnvironment,
  selectActiveOrganization,
  selectActiveOrganizationApprovedEnvironments,
  selectUserState,
} from '../../store/selectors/user.selectors';
import { MessageService } from '../message/message.service';
import { UserState } from '../../store/reducers/user.reducers';
import {
  Role,
  RoleAccessType,
  RoleResourceType,
  RoleStatusType,
} from '../../models/api/role.model';
import { ToastrService } from 'ngx-toastr';
import { mqttIsConnected } from '../../store/selectors/mqtt.selectors';
import { OrganizationRegistrationStartedMessage } from '../../models/api/message/organizations/organization-registration-started.message';
import { OrganizationRegistrationSubmittedMessage } from '../../models/api/message/organizations/organization-registration-submitted.message';
import { Environment } from '../../models/api/environment.model';
import { Organization } from '../../models/api/organization.model';
import { UserRoleMessage } from '../../models/api/message/users/user-role.message';
import { UserMessage } from '../../models/api/message/users/user.message';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: UserState = null;

  isPlatformOwner = false;
  isPlatformAdmin = false;
  isPlatformUser = false;

  activeOrganization: Organization = null;
  activeEnvironment: Environment = null;

  activeOrganizationApprovedEnvironments: Array<Environment> = null;

  constructor(
    private store: Store,
    private endpointService: EndpointService,
    private messageService: MessageService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.store
      .pipe(
        select(mqttIsConnected),
        filter((mqttIsConnected) => mqttIsConnected === true),
        take(1)
      )
      .subscribe((mqttIsConnected: boolean) => {
        if (mqttIsConnected) {
          this.initializeMessageSubscriptions();
        }
      });

    this.store
      .pipe(select(selectActiveOrganizationApprovedEnvironments))
      .subscribe((environments) => {
        this.activeOrganizationApprovedEnvironments = environments;
      });

    combineLatest([
      this.store.pipe(select(selectUserState)),
      this.store.pipe(select(isPlatformOwner)),
      this.store.pipe(select(isPlatformAdmin)),
      this.store.pipe(select(isPlatformUser)),
      this.store.pipe(select(selectActiveOrganization)),
      this.store.pipe(select(selectActiveEnvironment)),
    ]).subscribe(
      ([
        user,
        isPlatformOwner,
        isPlatformAdmin,
        isPlatformUser,
        activeOrganization,
        activeEnvironment,
      ]: [UserState, boolean, boolean, boolean, Organization, Environment]) => {
        this.user = user;

        this.isPlatformOwner = isPlatformOwner;
        this.isPlatformAdmin = isPlatformAdmin;
        this.isPlatformUser = isPlatformUser;

        this.activeOrganization = activeOrganization;
        this.activeEnvironment = activeEnvironment;
      }
    );
  }

  loadUser() {
    this.endpointService.user().subscribe((response) => {
      if (response.success) {
        this.store.dispatch(
          UserActions.load({
            _id: response.data._id,
            email: response.data.email,
            username: response.data.username,
            ipa_id: response.data.ipa_id,
            piv_cert: response.data.piv_cert,
            piv_cn: response.data.piv_cn,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            phone_prefix: response.data.phone_prefix,
            phone: response.data.phone,
            status: response.data.status,
            events: response.data.events,
            active_organization: response.data.active_organization,
            active_environment: response.data.active_environment,
            active_cluster_service: response.data.active_cluster_service,
            roles: response.data.roles,
          })
        );
      }
    });
  }

  initializeMessageSubscriptions() {
    /*****
     * User Message
     *****/
    this.messageService
      .topic<UserMessage>(UserMessage, this.user)
      .subscribe((userMessage: UserMessage) => {
        if (userMessage.data.title && userMessage.data.message) {
          this.toastr.success(
            userMessage.data.message,
            userMessage.data.title,
            {
              closeButton: true,
            }
          );
        }
      });

    /*****
     * User Role Message
     *****/
    this.messageService
      .topic<UserRoleMessage>(UserRoleMessage, this.user)
      .subscribe((userRoleMessage: UserRoleMessage) => {
        const role = this.user.roles.find((r) => {
          return r._id === userRoleMessage.data.role._id;
        });

        // Add / Remove / Update Role
        if (
          !role &&
          userRoleMessage.data.role.status === RoleStatusType.ENABLED
        ) {
          this.store.dispatch(UserActions.addRole(userRoleMessage.data.role));
        } else if (
          role &&
          role.status !== RoleStatusType.DISABLED &&
          role.status !== RoleStatusType.ARCHIVED &&
          (userRoleMessage.data.role.status === RoleStatusType.DISABLED ||
            userRoleMessage.data.role.status === RoleStatusType.ARCHIVED)
        ) {
          this.store.dispatch(
            UserActions.removeRole(userRoleMessage.data.role)
          );
        } else if (role) {
          this.store.dispatch(
            UserActions.updateRole(userRoleMessage.data.role)
          );
        }

        // Set / Unset Active Environment
        if (
          userRoleMessage.data.role.resource === RoleResourceType.ENVIRONMENT
        ) {
          if (
            (!role || role.status === RoleStatusType.DISABLED) &&
            userRoleMessage.data.role.status === RoleStatusType.ENABLED
          ) {
            if (
              this.activeOrganizationApprovedEnvironments.some(
                (environment) =>
                  environment._id === userRoleMessage.data.role.environment._id
              ) &&
              !this.user.active_environment &&
              this.activeOrganizationApprovedEnvironments.length === 1
            ) {
              this.store.dispatch(
                UserActions.setActiveEnvironment(
                  userRoleMessage.data.role.environment
                )
              );
            }
          } else if (
            role &&
            (userRoleMessage.data.role.status === RoleStatusType.DISABLED ||
              userRoleMessage.data.role.status === RoleStatusType.ARCHIVED)
          ) {
            if (
              this.activeEnvironment &&
              this.activeEnvironment._id ===
                userRoleMessage.data.role.environment._id
            ) {
              this.store.dispatch(
                UserActions.unsetActiveEnvironment(
                  userRoleMessage.data.role.environment
                )
              );
            }
          }
        }

        // Set / Unset Active Organization
        if (
          userRoleMessage.data.role.resource === RoleResourceType.ORGANIZATION
        ) {
          if (
            userRoleMessage.data.role.status === RoleStatusType.ENABLED &&
            (!role || role.status !== RoleStatusType.ENABLED)
          ) {
            if (!this.activeOrganization) {
              this.store.dispatch(
                UserActions.setActiveOrganization(
                  userRoleMessage.data.role.organization
                )
              );
            }
          } else if (
            userRoleMessage.data.role.status === RoleStatusType.DISABLED ||
            userRoleMessage.data.role.status === RoleStatusType.ARCHIVED
          ) {
            if (
              this.activeOrganization &&
              this.activeOrganization._id ===
                userRoleMessage.data.role.organization._id
            ) {
              this.store.dispatch(
                UserActions.unsetActiveOrganization(
                  userRoleMessage.data.role.organization
                )
              );

              const orgRoles = this.user.roles.filter(
                (r) =>
                  r.resource === RoleResourceType.ORGANIZATION &&
                  r.organization._id !==
                    userRoleMessage.data.role.organization._id
              );

              if (orgRoles.length === 1) {
                this.store.dispatch(
                  UserActions.setActiveOrganization(orgRoles[0].organization)
                );

                if (this.activeOrganizationApprovedEnvironments.length === 1) {
                  this.store.dispatch(
                    UserActions.setActiveEnvironment(
                      this.activeOrganizationApprovedEnvironments[0]
                    )
                  );
                }
              }
            }
          }
        }
      });

    /*****
     * Add Necessary Topic Subscriptions Based On User Roles
     *****/
    for (const role of this.user.roles) {
      if (
        role.resource === RoleResourceType.ORGANIZATION &&
        role.status === RoleStatusType.ENABLED
      ) {
        if (
          role.access === RoleAccessType.OWNER ||
          role.access === RoleAccessType.ADMIN
        ) {
          // User started registration to an Organization I am an Admin for
          this.messageService
            .topic<OrganizationRegistrationStartedMessage>(
              OrganizationRegistrationStartedMessage,
              role.organization
            )
            .subscribe(
              (
                organizationRegistrationStartedMessage: OrganizationRegistrationStartedMessage
              ) => {
                this.toastr.success(
                  `${organizationRegistrationStartedMessage.data.user.email} started the registration process to the ${organizationRegistrationStartedMessage.data.organization.name} organization`,
                  'User Started Registration Process',
                  {}
                );
              }
            );

          // User submitted registration to an Organization I am an Admin for
          this.messageService
            .topic<OrganizationRegistrationSubmittedMessage>(
              OrganizationRegistrationSubmittedMessage,
              role.organization
            )
            .subscribe(
              (
                organizationRegistrationSubmittedMessage: OrganizationRegistrationSubmittedMessage
              ) => {
                this.toastr.success(
                  `${organizationRegistrationSubmittedMessage.data.user.email} submitted their registration to the ${organizationRegistrationSubmittedMessage.data.organization.name} organization and is ready for review.`,
                  'User Submitted Registration Process',
                  {
                    closeButton: true,
                  }
                );
              }
            );
        }
      }
    }
  }
}
