<div #splashScreen id="splash-screen">
  <div class="logo-box">
    <svg width="120px" height="120px" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle r="37" cx="70" cy="150" fill="transparent" stroke-dasharray="250" stroke-dashoffset="250" stroke="black" stroke-width="15" transform="rotate(103, 100, 100)" stroke-linecap="round">
        <animate attributeName="stroke-dashoffset" values="250; 80; 80; 250; 250" times="0; 0.4; 0.7; 1; 1" dur="5s" repeatCount="indefinite" />
      </circle>
    </svg>
    <div class="logo">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>
