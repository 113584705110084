import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { select, Store } from '@ngrx/store';
import { UserState } from '../../store/reducers/user.reducers';
import {
  RoleEnforcementType,
  RolePlatformType,
} from '../../models/api/role.model';
import {
  isLoggedIn,
  selectPlatformRole,
} from '../../store/selectors/auth.selectors';
import { filter } from 'rxjs/operators';
import { withLatestFrom } from 'rxjs';
import {
  isActiveEnvironmentAdmin,
  isActiveEnvironmentOwner,
  isActiveEnvironmentUser,
  isActiveOrganizationAdmin,
  isActiveOrganizationOwner,
  isActiveOrganizationUser,
  userLoaded,
} from '../../store/selectors/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  private user: UserState = null;

  private platformRole: RolePlatformType = null;

  private isActiveOrganizationOwner: boolean = false;
  private isActiveOrganizationAdmin: boolean = false;
  private isActiveOrganizationUser: boolean = false;

  private isActiveEnvironmentOwner: boolean = false;
  private isActiveEnvironmentAdmin: boolean = false;
  private isActiveEnvironmentUser: boolean = false;

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private store: Store
  ) {
    super(router, keycloak);

    this.store
      .pipe(
        select(isLoggedIn),
        filter((isLoggedIn) => isLoggedIn === true),
        withLatestFrom(this.store.pipe(select(selectPlatformRole)))
      )
      .subscribe(([isLoggedIn, platformRole]: [boolean, RolePlatformType]) => {
        if (isLoggedIn) {
          this.platformRole = platformRole;
        }
      });

    this.store
      .pipe(
        select(userLoaded),
        filter((userLoaded) => userLoaded === true),
        withLatestFrom(
          this.store.pipe(select(isActiveOrganizationOwner)),
          this.store.pipe(select(isActiveOrganizationAdmin)),
          this.store.pipe(select(isActiveOrganizationUser)),
          this.store.pipe(select(isActiveEnvironmentOwner)),
          this.store.pipe(select(isActiveEnvironmentAdmin)),
          this.store.pipe(select(isActiveEnvironmentUser))
        )
      )
      .subscribe(
        ([
          userLoaded,
          isActiveOrganizationOwner,
          isActiveOrganizationAdmin,
          isActiveOrganizationUser,
          isActiveEnvironmentOwner,
          isActiveEnvironmentAdmin,
          isActiveEnvironmentUser,
        ]: [boolean, boolean, boolean, boolean, boolean, boolean, boolean]) => {
          if (userLoaded) {
            this.isActiveOrganizationOwner = isActiveOrganizationOwner;
            this.isActiveOrganizationAdmin = isActiveOrganizationAdmin;
            this.isActiveOrganizationUser = isActiveOrganizationUser;

            this.isActiveEnvironmentOwner = isActiveEnvironmentOwner;
            this.isActiveEnvironmentAdmin = isActiveEnvironmentAdmin;
            this.isActiveEnvironmentUser = isActiveEnvironmentUser;
          }
        }
      );
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // Get the roles required from the route.
    const roles: RolePlatformType[] = route.data['roles'];
    const enforcement: RoleEnforcementType = route.data['enforcement'];

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(roles instanceof Array) || roles.length === 0) {
      return true;
    } else {
      switch (enforcement) {
        case RoleEnforcementType.ALLOW_IF_ONE:
          return roles.some((role) => this.roles.includes(role));
          break;
        case RoleEnforcementType.ALLOW_IF_ALL:
          return roles.every((role) => this.roles.includes(role));
          break;
        case RoleEnforcementType.DENY_IF_ONE:
          break;
        case RoleEnforcementType.DENY_IF_ALL:
          break;
      }
    }

    return this.hasValidPlatformRole() && this.hasValidUserRole();

    // Allow the user to proceed if all the required roles are present.
  }

  private hasValidPlatformRole(): boolean {
    return false;
  }

  private hasValidUserRole(): boolean {
    return false;
  }

  private hasValidPersonaRole(): boolean {
    return false;
  }
}
