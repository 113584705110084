import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  configLoaded,
  selectConfigState,
} from '../../store/selectors/config.selectors';
import { filter } from 'rxjs/operators';
import { withLatestFrom } from 'rxjs';
import { ConfigState } from '../../store/reducers/config.reducers';
import { Organization } from '../../models/api/organization.model';
import { Environment } from '../../models/api/environment.model';
import { User } from '../../models/api/user.model';
import { OrganizationComponent } from '../../pages/administration/organization/organization.component';
import { FileSystem } from '../../models/api/file-system.model';
import { S3Bucket } from '../../models/api/s3-bucket.model';
import { HdfsCluster } from '../../models/api/hdfs-cluster.model';
import { Notebook } from '../../models/api/notebook.model';
import { Ide } from '../../models/api/ide.model';
import { Kafka } from '../../models/api/kafka.model';
import { KafkaTopic } from '../../models/api/kafka-topic.model';
import { PostgresCluster } from '../../models/api/postgres-cluster.model';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  private config: ConfigState = null;

  HOST: string = null;
  HOST_PIV: string = null;

  API_V1: string = '/api/v1';

  URL = {
    // Config
    CONFIG: () => {
      if (window.location.hostname === 'localhost') {
        return `http://localhost:3000/api/v1/config`;
      } else {
        return `/api/v1/config`;
      }
    },
    // Account Registration
    ACCOUNTS_REGISTRATION: (token: string) => {
      return this.HOST + this.API_V1 + `/accounts/registration/${token}`;
    },
    ACCOUNTS_REGISTRATION_USERNAME: (token: string) => {
      return (
        this.HOST + this.API_V1 + `/accounts/registration/${token}/username`
      );
    },
    ACCOUNTS_REGISTRATION_PHONE: (token: string) => {
      return this.HOST + this.API_V1 + `/accounts/registration/${token}/phone`;
    },
    ACCOUNTS_REGISTRATION_EMAIL_CODE: (token: string) => {
      return this.HOST + this.API_V1 + `/accounts/registration/${token}/email`;
    },
    ACCOUNTS_REGISTRATION_EMAIL_VERIFY: (token: string) => {
      return (
        this.HOST + this.API_V1 + `/accounts/registration/${token}/email/verify`
      );
    },
    ACCOUNTS_REGISTRATION_PHONE_CODE: (token: string) => {
      return this.HOST + this.API_V1 + `/accounts/registration/${token}/phone`;
    },
    ACCOUNTS_REGISTRATION_PHONE_VERIFY: (token: string) => {
      return (
        this.HOST + this.API_V1 + `/accounts/registration/${token}/phone/verify`
      );
    },
    ACCOUNTS_REGISTRATION_SET_PASSWORD: (token: string) => {
      return (
        this.HOST + this.API_V1 + `/accounts/registration/${token}/password`
      );
    },
    ACCOUNTS_REGISTRATION_GENERATE_OTP_TOKEN: (token: string) => {
      return this.HOST + this.API_V1 + `/accounts/registration/${token}/otp`;
    },
    ACCOUNTS_REGISTRATION_VERIFY_OTP_TOKEN: (token: string) => {
      return (
        this.HOST + this.API_V1 + `/accounts/registration/${token}/otp/verify`
      );
    },
    ACCOUNTS_REGISTRATION_REGISTER_PIV: (token: string) => {
      return (
        this.HOST_PIV + this.API_V1 + `/accounts/registration/${token}/piv`
      );
    },
    ACCOUNTS_REGISTRATION_SUBMIT: (token: string) => {
      return this.HOST + this.API_V1 + `/accounts/registration/${token}/submit`;
    },
    ACCOUNTS_REGISTRATION_PASSWORD_VERIFY: (token: string) => {
      return (
        this.HOST +
        this.API_V1 +
        `/accounts/registration/${token}/password/verify`
      );
    },
    ACCOUNTS_FORGOT_PASSWORD_EMAIL: () => {
      return this.HOST + this.API_V1 + `/accounts/forgot-password/email`;
    },
    ACCOUNTS_FORGOT_PASSWORD_EMAIL_VERIFY: () => {
      return this.HOST + this.API_V1 + `/accounts/forgot-password/email/verify`;
    },
    ACCOUNTS_FORGOT_PASSWORD_SMS: () => {
      return this.HOST + this.API_V1 + `/accounts/forgot-password/sms`;
    },
    ACCOUNTS_FORGOT_PASSWORD_SMS_VERIFY: () => {
      return this.HOST + this.API_V1 + `/accounts/forgot-password/sms/verify`;
    },

    // Users
    USER: () => {
      return this.HOST + this.API_V1 + '/user';
    },
    USER_AUTH: () => {
      return this.HOST + this.API_V1 + '/user/auth';
    },
    USER_ASSIGNED_ENVIRONMENTS: (orgId?: string) => {
      return (
        this.HOST + this.API_V1 + `/user/organization/${orgId}/environments`
      );
    },
    USER_ACTIVE_ORGANIZATION: () => {
      return this.HOST + this.API_V1 + '/user/active-organization';
    },
    USER_ACTIVE_ENVIRONMENT: () => {
      return this.HOST + this.API_V1 + '/user/active-environment';
    },
    USER_ORGANIZATION_REGISTRATION: (
      user: User,
      organization: Organization
    ) => {
      return (
        this.HOST +
        this.API_V1 +
        `/user/${user._id}/organization/${organization._id}/registration`
      );
    },
    USER_LOGOUT_SERVICES: () => {
      return this.HOST + this.API_V1 + '/user/logout-services';
    },

    // Organizations
    ORGANIZATION: (organization?: Organization) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization${organization ? '/' + organization._id : ''}`
      );
    },
    ORGANIZATION_CAPACITY_USERS_AVAILABLE: (organization: Organization) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/capacity/users/available`
      );
    },
    ORGANIZATION_CAPACITY_ENVIRONMENTS_UTILIZED: (
      organization: Organization
    ) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/capacity/environments/utilized`
      );
    },
    ORGANIZATION_CAPACITY_USERS_UTILIZED: (organization: Organization) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/capacity/users/utilized`
      );
    },
    ORGANIZATION_CAPACITY_RESOURCES_UTILIZED: (organization: Organization) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/capacity/resources/utilized`
      );
    },
    ORGANIZATION_USERS: (organization: Organization, user?: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/users${user ? `/${user._id}` : ''}`
      );
    },
    ORGANIZATION_USER_DISABLE: (organization: Organization, user: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/user/${user._id}/disable`
      );
    },
    ORGANIZATION_USER_ENABLE: (organization: Organization, user: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/user/${user._id}/enable`
      );
    },
    ORGANIZATION_USER: (organization: Organization, user?: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/user${user ? `/${user._id}` : ''}`
      );
    },
    ORGANIZATION_USER_APPROVE: (organization: Organization, user: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/user/${user._id}/approve`
      );
    },
    ORGANIZATION_USER_REJECT: (organization: Organization, user: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/user/${user._id}/reject`
      );
    },
    ORGANIZATION_ENVIRONMENT: (id: string, environment?: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${id}/environment${
          environment ? `/${environment._id}` : ''
        }`
      );
    },
    ORGANIZATION_USER_ROLES: (organization: Organization) => {
      return (
        this.HOST + this.API_V1 + `/organization/${organization._id}/user/roles`
      );
    },
    ORGANIZATION_ENVIRONMENTS: (id: string) => {
      return this.HOST + this.API_V1 + `/organization/${id}/environments`;
    },

    // Environments
    ENVIRONMENT: (environment?: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment${environment ? `/${environment._id}` : ''}`
      );
    },

    ENVIRONMENT_CLUSTER_SERVICES: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/cluster-services`
      );
    },

    ENVIRONMENT_USER_ROLES: (environment: Environment) => {
      return (
        this.HOST + this.API_V1 + `/environment/${environment._id}/user/roles`
      );
    },

    ENVIRONMENT_CAPACITY_USERS_AVAILABLE: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/capacity/users/available`
      );
    },

    ENVIRONMENT_CREATE: (organization: Organization) => {
      return (
        this.HOST +
        this.API_V1 +
        `/organization/${organization._id}/environment`
      );
    },

    ENVIRONMENT_CAPACITY_USERS_UTILIZED: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/capacity/users/utilized`
      );
    },

    ENVIRONMENT_USERS: (environment: Environment, user?: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/users${user ? `/${user._id}` : ''}`
      );
    },

    ENVIRONMENT_USER_DISABLE: (environment: Environment, user: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/user/${user._id}/disable`
      );
    },

    ENVIRONMENT_USER_ENABLE: (environment: Environment, user: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/user/${user._id}/enable`
      );
    },

    ENVIRONMENT_USER: (environment: Environment, user?: User) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/user${user ? `/${user._id}` : ''}`
      );
    },

    ENVIRONMENT_NOTEBOOK_BUILD: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/notebook/build`
      );
    },

    ENVIRONMENT_NOTEBOOKS: (environment: Environment) => {
      return (
        this.HOST + this.API_V1 + `/environment/${environment._id}/notebooks`
      );
    },

    ENVIRONMENT_IDE_BUILD: (environment: Environment) => {
      return (
        this.HOST + this.API_V1 + `/environment/${environment._id}/ide/build`
      );
    },

    ENVIRONMENT_IDES: (environment: Environment) => {
      return this.HOST + this.API_V1 + `/environment/${environment._id}/ides`;
    },

    ENVIRONMENT_DATA_LAKES: (id: string) => {
      return this.HOST + this.API_V1 + `/environment/${id}/data-lakes`;
    },

    ENVIRONMENT_FILE_SYSTEMS: (environment: Environment) => {
      return (
        this.HOST + this.API_V1 + `/environment/${environment._id}/file-systems`
      );
    },

    ENVIRONMENT_S3_BUCKETS: (environment: Environment) => {
      return (
        this.HOST + this.API_V1 + `/environment/${environment._id}/s3-buckets`
      );
    },

    ENVIRONMENT_HDFS_CLUSTERS: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/hdfs-clusters`
      );
    },

    ENVIRONMENT_POSTGRES_CLUSTERS: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/postgres-clusters`
      );
    },

    ENVIRONMENT_KAFKAS: (environment: Environment) => {
      return this.HOST + this.API_V1 + `/environment/${environment._id}/kafkas`;
    },

    KAFKA_TOPICS: (kafka: Kafka) => {
      return this.HOST + this.API_V1 + `/kafka/${kafka._id}/topics`;
    },

    ENVIRONMENT_FILE_SYSTEM: (
      environment: Environment,
      file_system?: FileSystem
    ) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/file-system${
          file_system ? `/${file_system._id}` : ''
        }`
      );
    },

    ENVIRONMENT_POSTGRES_CLUSTER: (
      environment: Environment,
      postgres_cluster?: PostgresCluster
    ) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/postgres-cluster${
          postgres_cluster ? `/${postgres_cluster._id}` : ''
        }`
      );
    },

    ENVIRONMENT_FILE_SYSTEM_EXISTS: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/file-system/exists`
      );
    },

    ENVIRONMENT_S3_BUCKET_EXISTS: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/s3-bucket/exists`
      );
    },

    ENVIRONMENT_HDFS_CLUSTER_EXISTS: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/hdfs-cluster/exists`
      );
    },

    ENVIRONMENT_POSTGRES_CLUSTER_EXISTS: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/postgres-cluster/exists`
      );
    },

    ENVIRONMENT_KAFKA_EXISTS: (environment: Environment) => {
      return (
        this.HOST + this.API_V1 + `/environment/${environment._id}/kafka/exists`
      );
    },

    ENVIRONMENT_KAFKA_TOPIC_EXISTS: (kafka: Kafka) => {
      return this.HOST + this.API_V1 + `/kafka/${kafka._id}/topic/exists`;
    },

    ENVIRONMENT_S3_BUCKET: (environment: Environment, s3_bucket?: S3Bucket) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/s3-bucket${
          s3_bucket ? `/${s3_bucket._id}` : ''
        }`
      );
    },

    ENVIRONMENT_HDFS_CLUSTER: (
      environment: Environment,
      hdfsCluster?: HdfsCluster
    ) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/hdfs-cluster${
          hdfsCluster ? `/${hdfsCluster._id}` : ''
        }`
      );
    },

    ENVIRONMENT_KAFKA: (environment: Environment, kafka?: Kafka) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/kafka${kafka ? `/${kafka._id}` : ''}`
      );
    },

    ENVIRONMENT_RELATIONAL_DATABASES: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/relational-databases`
      );
    },

    ENVIRONMENT_RELATIONAL_DATABASE: (environment: Environment) => {
      return (
        this.HOST +
        this.API_V1 +
        `/environment/${environment._id}/relational-database`
      );
    },

    ENVIRONMENT_USERS_SEARCH: (environment: Environment) => {
      return (
        this.HOST + this.API_V1 + `/environment/${environment._id}/users/search`
      );
    },

    DATA_LAKES_FILE_SYSTEM: (id: string) => {
      return this.HOST + this.API_V1 + `/environment/${id}/hdfs-cluster`;
    },

    // File System
    FILE_SYSTEM_FILE_METADATA: (fileSystem: FileSystem, path: string) => {
      return (
        this.HOST +
        this.API_V1 +
        `/file-system/${fileSystem._id}/file/${path}/metadata`
      );
    },

    FILE_SYSTEM_USERS: (fileSystem: FileSystem) => {
      return this.HOST + this.API_V1 + `/file-system/${fileSystem._id}/users`;
    },

    S3_BUCKET_USERS: (s3Bucket: S3Bucket) => {
      return this.HOST + this.API_V1 + `/s3-bucket/${s3Bucket._id}/users`;
    },

    HDFS_CLUSTER_USERS: (hdfsCluster: HdfsCluster) => {
      return this.HOST + this.API_V1 + `/hdfs-cluster/${hdfsCluster._id}/users`;
    },

    POSTGRES_CLUSTER_USERS: (postgresCluster: PostgresCluster) => {
      return (
        this.HOST +
        this.API_V1 +
        `/postgres-cluster/${postgresCluster._id}/users`
      );
    },

    KAFKA_USERS: (kafka: Kafka) => {
      return this.HOST + this.API_V1 + `/kafka/${kafka._id}/users`;
    },

    FILE_SYSTEM_USERS_SEARCH: (fileSystem: FileSystem) => {
      return (
        this.HOST + this.API_V1 + `/file-system/${fileSystem._id}/users/search`
      );
    },

    S3_BUCKET_USERS_SEARCH: (s3Bucket: S3Bucket) => {
      return (
        this.HOST + this.API_V1 + `/s3-bucket/${s3Bucket._id}/users/search`
      );
    },

    HDFS_CLUSTER_USERS_SEARCH: (hdfsCluster: HdfsCluster) => {
      return (
        this.HOST +
        this.API_V1 +
        `/hdfs-cluster/${hdfsCluster._id}/users/search`
      );
    },

    POSTGRES_CLUSTER_USERS_SEARCH: (postgresCluster: PostgresCluster) => {
      return (
        this.HOST +
        this.API_V1 +
        `/postgres-cluster/${postgresCluster._id}/users/search`
      );
    },

    KAFKA_USERS_SEARCH: (kafka: Kafka) => {
      return this.HOST + this.API_V1 + `/kafka/${kafka._id}/users/search`;
    },

    FILE_SYSTEM_USER: (fileSystem: FileSystem) => {
      return this.HOST + this.API_V1 + `/file-system/${fileSystem._id}/user`;
    },

    S3_BUCKET_USER: (s3Bucket: S3Bucket) => {
      return this.HOST + this.API_V1 + `/s3-bucket/${s3Bucket._id}/user`;
    },

    HDFS_CLUSTER_USER: (hdfsCluster: HdfsCluster) => {
      return this.HOST + this.API_V1 + `/hdfs-cluster/${hdfsCluster._id}/user`;
    },

    POSTGRES_CLUSTER_USER: (postgresCluster: PostgresCluster) => {
      return (
        this.HOST +
        this.API_V1 +
        `/postgres-cluster/${postgresCluster._id}/user`
      );
    },

    POSTGRES_CLUSTER_SECRET: (postgresCluster: PostgresCluster) => {
      return (
        this.HOST +
        this.API_V1 +
        `/postgres-cluster/${postgresCluster._id}/secret`
      );
    },

    KAFKA_USER: (kafka: Kafka) => {
      return this.HOST + this.API_V1 + `/kafka/${kafka._id}/user`;
    },

    KAFKA_TOPIC: (kafka: Kafka, kafkaTopic?: KafkaTopic) => {
      return (
        this.HOST +
        this.API_V1 +
        `/kafka/${kafka._id}/topic${kafkaTopic ? `/${kafkaTopic._id}` : ''}`
      );
    },

    FILE_SYSTEM_FILE: (fileSystem: FileSystem) => {
      return this.HOST + `/api/v1/file-system/${fileSystem._id}/files`;
    },

    // Launcher
    LAUNCHERS_SKILLS_ASSESSMENT: (id: string) => {
      return (
        this.HOST +
        this.API_V1 +
        `/launchers/environment/${id}/skills-assessment`
      );
    },
    LAUNCHERS_JITSI: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/jitsi`;
    },
    LAUNCHERS_MATTERMOST: (id: string) => {
      return (
        this.HOST + this.API_V1 + `/launchers/environment/${id}/mattermost`
      );
    },
    LAUNCHERS_WIKIJS: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/wikijs`;
    },
    LAUNCHERS_OPENPROJECT: (id: string) => {
      return (
        this.HOST + this.API_V1 + `/launchers/environment/${id}/openproject`
      );
    },
    LAUNCHERS_KIBANA: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/kibana`;
    },
    LAUNCHERS_AIRBYTE: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/airbyte`;
    },
    LAUNCHERS_AIRFLOW: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/airflow`;
    },
    LAUNCHERS_HOP: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/hop`;
    },
    LAUNCHERS_STREAMPIPES: (id: string) => {
      return (
        this.HOST + this.API_V1 + `/launchers/environment/${id}/streampipes`
      );
    },
    LAUNCHERS_PGADMIN: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/pgadmin`;
    },
    LAUNCHERS_GRAFANA: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/grafana`;
    },
    LAUNCHERS_NIFI: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/nifi`;
    },
    LAUNCHERS_DRIVERLESS_AI: (id: string) => {
      return (
        this.HOST + this.API_V1 + `/launchers/environment/${id}/driverless-ai`
      );
    },
    LAUNCHERS_HUE: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/hue`;
    },
    LAUNCHERS_NOTEBOOK: (id: string, uuid: string) => {
      return (
        this.HOST +
        this.API_V1 +
        `/launchers/environment/${id}/notebook/${uuid}`
      );
    },
    LAUNCHERS_QUERYBOOK: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/querybook`;
    },
    LAUNCHERS_OVALEDGE: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/ovaledge`;
    },
    LAUNCHERS_IDE: (id: string, uuid: string) => {
      return (
        this.HOST + this.API_V1 + `/launchers/environment/${id}/ide/${uuid}`
      );
    },
    LAUNCHERS_GITEA: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/gitea`;
    },
    LAUNCHERS_ARGO: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/argo`;
    },
    LAUNCHERS_APIMAN: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/apiman`;
    },
    LAUNCHERS_ARCHIVA: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/archiva`;
    },
    LAUNCHERS_SUPERSET: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/superset`;
    },
    LAUNCHERS_ENTANDO: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/entando`;
    },
    LAUNCHERS_JBPM: (id: string) => {
      return this.HOST + this.API_V1 + `/launchers/environment/${id}/jbpm`;
    },

    // File
    FILES: () => {
      return this.HOST + this.API_V1 + '/files';
    },
    FILES_CHUNK: () => {
      return this.HOST + this.API_V1 + '/files/chunk';
    },
    FILES_DIRECTORY: () => {
      return this.HOST + this.API_V1 + '/files/directory';
    },
    FILES_METADATA: () => {
      return this.HOST + this.API_V1 + '/files/metadata';
    },

    // Notebook
    NOTEBOOK: (notebook?: Notebook) => {
      return (
        this.HOST +
        this.API_V1 +
        `/notebook${notebook ? `/${notebook._id}` : ''}`
      );
    },
    NOTEBOOK_START: (notebook: Notebook) => {
      return this.HOST + this.API_V1 + `/notebook/${notebook._id}/start`;
    },
    NOTEBOOK_STOP: (notebook: Notebook) => {
      return this.HOST + this.API_V1 + `/notebook/${notebook._id}/stop`;
    },
    NOTEBOOK_LOGS: (notebook: Notebook) => {
      return this.HOST + this.API_V1 + `/notebook/${notebook._id}/logs`;
    },
    NOTEBOOK_ARCHIVE: (notebook: Notebook) => {
      return this.HOST + this.API_V1 + `/notebook/${notebook._id}/archive`;
    },

    // IDE
    IDE: (ide?: Ide) => {
      return this.HOST + this.API_V1 + `/ide${ide ? `/${ide._id}` : ''}`;
    },
    IDE_START: (ide: Ide) => {
      return this.HOST + this.API_V1 + `/ide/${ide._id}/start`;
    },
    IDE_STOP: (ide: Ide) => {
      return this.HOST + this.API_V1 + `/ide/${ide._id}/stop`;
    },
    IDE_LOGS: (ide: Ide) => {
      return this.HOST + this.API_V1 + `/ide/${ide._id}/logs`;
    },
    IDE_ARCHIVE: (ide: Ide) => {
      return this.HOST + this.API_V1 + `/ide/${ide._id}/archive`;
    },

    // Cluster Services
    CLUSTER_SERVICE: () => {
      return this.HOST + this.API_V1 + '/cluster-service';
    },
    CLUSTER_SERVICE_FILESYSTEM_ATTACH: (id: string) => {
      return (
        this.HOST + this.API_V1 + `/cluster-service/${id}/file-system/attach`
      );
    },
    CLUSTER_SERVICE_FILESYSTEM_DETACH: (id: string) => {
      return (
        this.HOST + this.API_V1 + `/cluster-service/${id}/file-system/detach`
      );
    },
  };

  LOCAL_STORAGE = {
    UPLOADS: 'uploads',
    JWT: 'JWT',
  };

  FILE = {
    PAUSE_TIMEOUT: 5000,
    CHUNK_SIZE_BYTES: 1048576,
    ROUND_SIZE: 100,
    NUM_SIMULTANEOUS_UPLOADS: 10,
    BLOCK_SIZE: 10,
    RETRY_UPLOAD_DELAY_MS: 500,
  };

  // MQTT Topics
  public static MQTT = {
    TOPIC_FILE_CHUNKS_SAVED: (fileSha256: string) => {
      return 'file/chunks/saved/' + fileSha256;
    },
  };

  constructor(private store: Store) {
    this.store
      .pipe(
        select(configLoaded),
        filter((configLoaded) => configLoaded === true),
        withLatestFrom(this.store.pipe(select(selectConfigState))) // combine the isLoaded value with the config state
      )
      .subscribe(([configLoaded, config]: [boolean, ConfigState]) => {
        if (configLoaded) {
          this.config = config;
          this.HOST = `http${!config.development ? 's' : ''}://${
            config.hostname
          }:${config.port}`;

          this.HOST_PIV = `http${!config.development ? 's' : ''}://${
            (!config.development ? 'piv.' : '') + config.hostname
          }:${config.port}`;
        }
      });
  }
}
