import { createReducer, on } from '@ngrx/store';
import { AuthActions, RegistrationActions } from '../actions';
import { User } from '../../models/api/user.model';
import { ApiEvent } from '../../models/api/api.model';
import { Organization } from '../../models/api/organization.model';
import { Role } from '../../models/api/role.model';
import {
  AccountRegistrationEventMetadata,
  AccountRegistrationEventType,
  AccountRegistrationStatusType,
  AccountRegistrationType,
} from '../../models/api/account-registration.model';

export interface RegistrationState {
  _id: string;
  schema_version: number;
  registration: AccountRegistrationType;
  token: string;
  nonce: string;
  phone: string;
  phone_prefix: string;
  email_code: string;
  email_code_expiration: Date;
  email_codes_sent: number;
  email_code_incorrect_attempts: number;
  email_verified: boolean;
  phone_code: string;
  phone_code_expiration: Date;
  phone_codes_sent: number;
  phone_code_incorrect_attempts: number;
  phone_verified: boolean;
  password_incorrect_attempts: number;
  status: AccountRegistrationStatusType;
  events: Array<
    ApiEvent<AccountRegistrationEventType, AccountRegistrationEventMetadata>
  >;
  user: User;
  organization: Organization;
  role: Role;
}

export const initialRegistrationState: RegistrationState = {
  _id: undefined,
  schema_version: undefined,
  registration: undefined,
  token: undefined,
  nonce: undefined,
  phone: undefined,
  phone_prefix: undefined,
  email_code: undefined,
  email_code_expiration: undefined,
  email_codes_sent: undefined,
  email_code_incorrect_attempts: undefined,
  email_verified: undefined,
  phone_code: undefined,
  phone_code_expiration: undefined,
  phone_codes_sent: undefined,
  phone_code_incorrect_attempts: undefined,
  phone_verified: undefined,
  password_incorrect_attempts: undefined,
  status: undefined,
  events: undefined,
  user: undefined,
  organization: undefined,
  role: undefined,
};

export const registrationReducer = createReducer(
  initialRegistrationState,

  on(RegistrationActions.invalidRegistrationToken, (state, action) => {
    return {
      ...state,
      _id: null,
      type: action.type,
    };
  }),

  on(RegistrationActions.load, (state, action) => {
    return {
      ...state,
      _id: action._id,
      schema_version: action.schema_version,
      registration: action.registration,
      token: action.token,
      nonce: action.nonce,
      phone: action.phone,
      phone_prefix: action.phone_prefix,
      email_code: action.email_code,
      email_code_expiration: action.email_code_expiration,
      email_codes_sent: action.email_codes_sent,
      email_code_incorrect_attempts: action.email_code_incorrect_attempts,
      email_verified: action.email_verified,
      phone_code: action.phone_code,
      phone_code_expiration: action.phone_code_expiration,
      phone_codes_sent: action.phone_codes_sent,
      phone_code_incorrect_attempts: action.phone_code_incorrect_attempts,
      phone_verified: action.phone_verified,
      password_incorrect_attempts: action.password_incorrect_attempts,
      status: action.status,
      events: action.events,
      user: action.user,
      organization: action.organization,
      role: action.role,
    };
  })
);
