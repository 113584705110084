export enum MqttStatusType {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  ERROR = 'ERROR',
}

export enum MqttTopicStatusType {
  CREATED = 'CREATED',
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBING = 'UNSUBSCRIBING',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  SUBSCRIPTION_DENIED = 'SUBSCRIPTION_DENIED',
}

export enum MqttEventType {
  ONCONNECT = 'ONCONNECT',
  ONERROR = 'ONERROR',
  ONMESSAGE = 'ONMESSAGE',
  ONCLOSE = 'ONCLOSE',
  ONEND = 'ONEND',
  ONOFFLINE = 'ONOFFLINE',
  ONPACKETRECEIVE = 'ONPACKETRECEIVE',
  ONPACKETSEND = 'ONPACKETSEND',
  ONRECONNECT = 'ONRECONNECT',
  ONSUBACK = 'ONSUBACK',
}
