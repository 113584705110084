<app-splash-screen></app-splash-screen>
<router-outlet></router-outlet>

<ng-template #timeout_modal let-modal>
    <form>
        <div class="modal-header">
            <h4 class="modal-title">Inactivity Timeout</h4>
        </div>
        <div class="modal-body">
            <h4>You will be automatically logged out due to inactivity.</h4>
            <h4>
                <strong>Time Remaining:</strong> {{ timeout }} seconds
            </h4>
        </div>
        <div class="modal-footer">
        </div>
    </form>
</ng-template>
