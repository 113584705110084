import { MessagePayload } from '../payload.message';
import { MessageTopics } from '../topics.message';
import { Organization } from '../../organization.model';
import { User } from '../../user.model';

export class OrganizationRegistrationStartedMessage extends MessagePayload<{
  organization: Organization;
  user: User;
}> {
  constructor(organization: Organization, user: User) {
    super({
      title: 'Org Registration Started',
      description: `Registration Started for ${organization.name}`,
      icon: 'Communication/Add-user.svg',
      topic: MessageTopics.OrganizationRegistrationStartedTopic(organization),
      success: true,
      message: 'Organization Registration Started',
      data: { organization, user },
    });
  }
}
