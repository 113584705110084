import { MessagePayload } from '../../../api/message/payload.message';
import { MessageTopics } from '../../../api/message/topics.message';
import { User } from '../../../api/user.model';

export class UserMessage extends MessagePayload<{
  title: string;
  message: string;
}> {
  constructor(user: User, title: string, message: string) {
    super({
      title: 'User Messages',
      description: `User messages for user ${user.username}`,
      icon: 'General/User.svg',
      topic: MessageTopics.UserTopic(user),
      success: true,
      message: 'User Messages',
      data: { title, message },
    });
  }
}
