import { createAction, props } from '@ngrx/store';
import {
  MqttEventType,
  MqttStatusType,
  MqttTopicStatusType,
} from '../../models/mqtt.model';
import { MessagePayload } from '../../models/api/message/payload.message';

export const event = createAction(
  '[MqttAction] Event',
  props<{ event: MqttEventType }>()
);

export const updateStatus = createAction(
  '[MqttAction] Update Status',
  props<{ status: MqttStatusType }>()
);

export const createTopic = createAction(
  '[MqttAction] Create Topic',
  props<{ payload: MessagePayload<any>; status: MqttTopicStatusType }>()
);

export const updateTopicStatus = createAction(
  '[MqttAction] Update Topic Status',
  props<{ topic: string; status: MqttTopicStatusType }>()
);

export const topicMessageReceived = createAction(
  '[MqttAction] Topic Message Received',
  props<{ topic: string }>()
);

export const unsubscribeTopic = createAction(
  '[MqttAction] Unsubscribe Topic',
  props<{ topic: string }>()
);
