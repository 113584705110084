import { createAction, props } from '@ngrx/store';
import { KeycloakEventType } from 'keycloak-angular';

export const login = createAction(
  '[AuthAction] Login',
  props<{
    jwt: string;
  }>()
);

export const updateStatus = createAction(
  '[AuthAction] Update Status',
  props<{ status: KeycloakEventType }>()
);

export const logout = createAction('[AuthAction] Logout');
