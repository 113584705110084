import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RegistrationState } from '../reducers/registration.reducers';

export const selectRegistrationState =
  createFeatureSelector<RegistrationState>('registration');

export const invalidRegistrationToken = createSelector(
  selectRegistrationState,
  (registration): boolean => {
    return registration._id === null;
  }
);

export const registrationLoaded = createSelector(
  selectRegistrationState,
  (registration): boolean => {
    return !!registration && !!registration._id;
  }
);
