import { MessagePayload } from '../../../api/message/payload.message';
import { MessageTopics } from '../../../api/message/topics.message';
import { User } from '../../../api/user.model';
import { Role } from '../../../api/role.model';

export class UserRoleMessage extends MessagePayload<{
  role: Role;
}> {
  constructor(user: User, role: Role) {
    super({
      title: 'User Role Updates',
      description: `Roles updated for user ${user.username}`,
      icon: 'General/User.svg',
      topic: MessageTopics.UserRoleTopic(user),
      success: true,
      message: 'User Role Modified',
      data: { role },
    });
  }
}
