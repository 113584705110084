import { ClusterService } from '../cluster-service.model';
import { Environment } from '../environment.model';
import { Organization } from '../organization.model';
import { Notebook } from '../notebook.model';
import { Ide } from '../ide.model';
import { User } from '../user.model';
import { Pod } from '../pod.model';
import { FileSystem } from '../file-system.model';
import { S3Bucket } from '../s3-bucket.model';
import { HdfsCluster } from '../hdfs-cluster.model';
import { Workflow } from '../workflow.model';
import { PostgresCluster } from '../postgres-cluster.model';
import { Elasticsearch } from '../elasticsearch.model';
import { Kibana } from '../kibana.model';
import { Kafka } from '../kafka.model';
import { Service } from '../service.model';
import { KafkaConnector } from '../kafka-connector.model';
import { KafkaTopic } from '../kafka-topic.model';
import { KafkaUser } from '../kafka-user.model';
import { KafkaConnect } from '../kafka-connect.model';
import { Prometheus } from '../prometheus.model';
import { ServiceMonitor } from '../service-monitor.model';
import { Alertmanager } from '../alertmanager.model';
import { AlertmanagerConfig } from '../alertmanager-config.model';
import { PerconaServerMongoDb } from '../percona-server-mongo-db.model';
import { PerconaServerMongoDbBackup } from '../percona-server-mongo-db-backup.model';
import { PerconaServerMongoDbRestore } from '../percona-server-mongo-db-restore.model';
import { PerconaXtraDbCluster } from '../percona-xtra-db-cluster.model';
import { PerconaXtraDbClusterBackup } from '../percona-xtra-db-cluster-backup.model';
import { PerconaXtraDbClusterRestore } from '../percona-xtra-db-cluster-restore.model';
import { VolumeSnapshot } from '../volume-snapshot.model';
import { Bundle } from '../bundle.model';
import { Redis } from '../redis.model';
import { Task } from '../task.model';
import { Role } from '../role.model';
import { Node } from '../node.model';
import { Deployment } from '../deployment.model';
import { ResourceCapacity } from '../resource-capacity.model';
import { UserCapacity } from '../user-capacity.model';
import { AccountRegistration } from '../account-registration.model';
import { ResourceQuota } from '../resource-quota.model';
import { LimitRange } from '../limit-range.model';
import { Querybook } from '../querybook.model';
import { StatefulSet } from '../stateful-set.model';
import { Zookeeper } from '../zookeeper.model';
import { Certificate } from '../certificate.model';
import { Gateway } from '../gateway.model';
import { EnvoyFilter } from '../envoy-filter.model';
import { CiliumNetworkPolicy } from '../cilium-network-policy.model';
import { VirtualService } from '../virtual-service.model';
import { DestinationRule } from '../destination-rule.model';
import { Issuer } from '../issuer.model';
import { StripePrice } from '../stripe-price.model';
import { TemporalWorkflow } from '../temporal-workflow.model';
import { PersistentVolumeClaim } from '../persistent-volume-claim.model';
import { Job } from '../job.model';
import { ConfigMap } from '../config-map.model';
import { DaemonSet } from '../daemon-set.model';
import { ReplicaSet } from '../replica-set.model';
import { Secret } from '../secret.model';
import { NetworkPolicy } from '../network-policy.model';

export class MessageTopics {
  /***
   * Node
   */
  static NodesTopic(): string {
    return `node`;
  }

  static NodeTopic(uuid: string): string {
    return `node/${uuid}`;
  }

  static NodeStatusTopic(node: Node): string {
    return `node/${node._id}/status`;
  }

  static NodeEventTopic(node: Node): string {
    return `node/${node._id}/event`;
  }

  /***
   * Platform
   */
  static PlatformOwnerTopic(): string {
    return `platform/owner`;
  }

  static PlatformAdminTopic(): string {
    return `platform/admin`;
  }

  static PlatformUserTopic(): string {
    return `platform/user`;
  }

  /***
   * Platform
   */
  static PlatformUserAddedTopic(organization: Organization): string {
    return `organization/${organization._id}/user/add`;
  }

  /***
   * Task
   */
  static TaskStatusTopic(task: Task): string {
    return `task/${task._id}/status`;
  }

  static TaskEventTopic(task: Task): string {
    return `task/${task._id}/event`;
  }

  /***
   * Organization
   */
  static OrganizationStatusTopic(organization: Organization): string {
    return `organization/${organization._id}/status`;
  }

  static OrganizationEventTopic(organization: Organization): string {
    return `organization/${organization._id}/event`;
  }

  static OrganizationTopic(organization: Organization): string {
    return `organization/${organization._id}`;
  }

  static OrganizationUserAddedTopic(organization: Organization): string {
    return `organization/${organization._id}/user/added`;
  }

  static OrganizationEnvironmentAddedTopic(organization: Organization): string {
    return `organization/${organization._id}/environment/added`;
  }

  static OrganizationEnvironmentArchivedTopic(
    organization: Organization
  ): string {
    return `organization/${organization._id}/environment/archived`;
  }

  static OrganizationRegistrationStartedTopic(
    organization: Organization
  ): string {
    return `organization/${organization._id}/registration/started`;
  }

  static OrganizationRegistrationSubmittedTopic(
    organization: Organization
  ): string {
    return `organization/${organization._id}/registration/submitted`;
  }

  static OrganizationRoleTopic(organization: Organization): string {
    return `organization/${organization._id}/role`;
  }

  /***
   * Environment
   */
  static EnvironmentClusterServiceAddedTopic(environment: Environment): string {
    return `environment/${environment._id}/cluster-service/added`;
  }

  static EnvironmentStatusTopic(environment: Environment): string {
    return `environment/${environment._id}/status`;
  }

  static EnvironmentEventTopic(environment: Environment): string {
    return `environment/${environment._id}/event`;
  }

  static EnvironmentRoleTopic(environment: Environment): string {
    return `environment/${environment._id}/role`;
  }

  // DELETE ME
  static EnvironmentTopic(environment: Environment): string {
    return `environment/${environment._id}`;
  }

  // DELETE ME
  static EnvironmentNewClusterServiceTopic(environment: Environment): string {
    return `environment/${environment._id}/cluster-service`;
  }

  /***
   * Cluster Service
   */
  static ClusterServiceStatusTopic(cluster_service: ClusterService): string {
    return `cluster-service/${cluster_service._id}/status`;
  }

  static ClusterServiceEventTopic(cluster_service: ClusterService): string {
    return `cluster-service/${cluster_service._id}/event`;
  }

  static ClusterServiceStatusUpdate(clusterService: ClusterService): string {
    return `cluster-service/${clusterService._id}/status/update`;
  }

  static ClusterServiceEventUpdate(clusterService: ClusterService): string {
    return `cluster-service/${clusterService._id}/event/update`;
  }

  static ClusterServiceTopic(clusterService: ClusterService): string {
    return `cluster-service/${clusterService._id}`;
  }

  static PodTopic(pod: Pod): string {
    return `pod/${pod.cluster_service._id}`;
  }

  /***
   * Role
   */
  static RoleStatusTopic(role: Role): string {
    return `role/${role._id}/status`;
  }

  static RoleEventTopic(role: Role): string {
    return `role/${role._id}/event`;
  }

  /***
   * User
   */
  static UserTopic(user: User): string {
    return `user/${user._id}`;
  }

  static UserStatusTopic(user: User): string {
    return `user/${user._id}/status`;
  }

  static UserEventTopic(user: User): string {
    return `user/${user._id}/event`;
  }

  static UserRoleTopic(user: User): string {
    return `user/${user._id}/role`;
  }

  static UserEnvironmentAddedTopic(user: User): string {
    return `user/${user._id}/environment/added`;
  }

  static UserEnvironmentRemovedTopic(user: User): string {
    return `user/${user._id}/environment/removed`;
  }

  static UserFileSystemAddedTopic(user: User): string {
    return `user/${user._id}/file-system/added`;
  }

  static UserEnvironmentPrometheusRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/prometheus/role`;
  }

  static UserEnvironmentOrganizationRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/organization/role`;
  }

  static UserEnvironmentEnvironmentRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/environment/role`;
  }

  static UserEnvironmentPodRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/pod/role`;
  }

  static UserEnvironmentJobRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/job/role`;
  }

  static UserEnvironmentConfigMapRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/config-map/role`;
  }

  static UserEnvironmentDeploymentRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/deployment/role`;
  }

  static UserEnvironmentStatefulSetRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/stateful-set/role`;
  }

  static UserEnvironmentDaemonSetRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/daemon-set/role`;
  }

  static UserEnvironmentReplicaSetRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/replica-set/role`;
  }

  static UserEnvironmentServiceRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/service/role`;
  }

  static UserEnvironmentPersistentVolumeClaimRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/persistent-volume-claim/role`;
  }

  static UserEnvironmentServiceMonitorRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/service-monitor/role`;
  }

  static UserEnvironmentSecretRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/secret/role`;
  }

  static UserEnvironmentIssuerRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/issuer/role`;
  }

  static UserEnvironmentCertificateConfigRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/certificate/role`;
  }

  static UserEnvironmentNetworkPolicyRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/network-policy/role`;
  }

  static UserEnvironmentCiliumNetworkPolicyRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/cilium-network-policy/role`;
  }

  static UserEnvironmentGatewayRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/gateway/role`;
  }

  static UserEnvironmentLimitRangeRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/limit-range/role`;
  }

  static UserEnvironmentResourceQuotaRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/resource-quota/role`;
  }

  static UserEnvironmentVirtualServiceRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/virtual-service/role`;
  }

  static UserEnvironmentDestinationRuleRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/destination-rule/role`;
  }

  static UserEnvironmentAlertmanagerRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/alertmanager/role`;
  }

  static UserEnvironmentAlertmanagerConfigRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/alertmanager-config/role`;
  }

  static UserEnvironmentBundleRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/bundle/role`;
  }

  static UserEnvironmentVolumeSnapshotRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/volume-snapshot/role`;
  }

  static UserEnvironmentQuerybookRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/querybook/role`;
  }

  static UserEnvironmentRedisRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/redis/role`;
  }

  static UserEnvironmentZookeeperRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/zookeeper/role`;
  }

  static UserEnvironmentFileSystemRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/file-system/role`;
  }

  static UserEnvironmentEnvoyFilterRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/envoy-filter/role`;
  }

  static UserEnvironmentS3BucketRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/s3-bucket/role`;
  }

  static UserEnvironmentHdfsClusterRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/hdfs-cluster/role`;
  }

  static UserEnvironmentElasticsearchRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/elasticsearch/role`;
  }

  static UserEnvironmentKibanaRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/kibana/role`;
  }

  static UserEnvironmentPostgresClusterRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/postgres-cluster/role`;
  }

  static UserEnvironmentKafkaConnectorRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/kafka-connector/role`;
  }

  static UserEnvironmentKafkaConnectRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/kafka-connect/role`;
  }

  static UserEnvironmentKafkaTopicRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/kafka-topic/role`;
  }

  static UserEnvironmentKafkaUserRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/kafka-user/role`;
  }

  static UserEnvironmentKafkaRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/kafka/role`;
  }

  static UserKafkaKafkaTopicRoleTopic(user: User, kafka: Kafka): string {
    return `user/${user._id}/kafka/${kafka._id}/kafka-topic/role`;
  }

  static UserEnvironmentWorkflowRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/workflow/role`;
  }

  static UserEnvironmentNotebookRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/notebook/role`;
  }

  static UserEnvironmentIdeRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/ide/role`;
  }

  static UserEnvironmentPerconaServerMongoDbRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/percona-server-mongo-db/role`;
  }

  static UserEnvironmentPerconaServerMongoDbBackupRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/percona-server-mongo-db-backup/role`;
  }

  static UserEnvironmentPerconaServerMongoDbRestoreRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/percona-server-mongo-db-restore/role`;
  }

  static UserEnvironmentPerconaXtraDbClusterRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/percona-xtra-db-cluster/role`;
  }

  static UserEnvironmentPerconaXtraDbClusterBackupRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/percona-xtra-db-cluster-backup/role`;
  }

  static UserEnvironmentPerconaXtraDbClusterRestoreRoleTopic(
    user: User,
    environment: Environment
  ): string {
    return `user/${user._id}/environment/${environment._id}/percona-xtra-db-cluster-restore/role`;
  }

  /***
   * Stripe Price
   */
  static StripePriceStatusTopic(stripe_price: StripePrice): string {
    return `stripe-price/${stripe_price._id}/status`;
  }

  static StripePriceEventTopic(stripe_price: StripePrice): string {
    return `stripe-price/${stripe_price._id}/event`;
  }

  /***
   * Resource Capacity
   */
  static ResourceCapacityStatusTopic(
    resource_capacity: ResourceCapacity
  ): string {
    return `resource-capacity/${resource_capacity._id}/status`;
  }

  static ResourceCapacityEventTopic(
    resource_capacity: ResourceCapacity
  ): string {
    return `resource-capacity/${resource_capacity._id}/event`;
  }

  /***
   * User Capacity
   */
  static UserCapacityStatusTopic(user_capacity: UserCapacity): string {
    return `user-capacity/${user_capacity._id}/status`;
  }

  static UserCapacityEventTopic(user_capacity: UserCapacity): string {
    return `user-capacity/${user_capacity._id}/event`;
  }

  /***
   * Account Registration
   */
  static AccountRegistrationStatusTopic(
    account_registration: AccountRegistration
  ): string {
    return `account-registration/${account_registration._id}/status`;
  }

  static AccountRegistrationEventTopic(
    account_registration: AccountRegistration
  ): string {
    return `account-registration/${account_registration._id}/event`;
  }

  /***
   * Pod
   */
  static PodStatusTopic(pod: Pod): string {
    return `pod/${pod._id}/status`;
  }

  static PodEventTopic(pod: Pod): string {
    return `pod/${pod._id}/event`;
  }

  /***
   * Job
   */
  static JobStatusTopic(job: Job): string {
    return `job/${job._id}/status`;
  }

  static JobEventTopic(job: Job): string {
    return `job/${job._id}/event`;
  }

  /***
   * Config Map
   */
  static ConfigMapStatusTopic(config_map: ConfigMap): string {
    return `config-map/${config_map._id}/status`;
  }

  static ConfigMapEventTopic(config_map: ConfigMap): string {
    return `config-map/${config_map._id}/event`;
  }

  /***
   * Gateway
   */
  static GatewayStatusTopic(gateway: Gateway): string {
    return `gateway/${gateway._id}/status`;
  }

  static GatewayEventTopic(gateway: Gateway): string {
    return `gateway/${gateway._id}/event`;
  }

  /***
   * Virtual Service
   */
  static VirtualServiceStatusTopic(virtual_service: VirtualService): string {
    return `virtual-service/${virtual_service._id}/status`;
  }

  static VirtualServiceEventTopic(virtual_service: VirtualService): string {
    return `virtual-service/${virtual_service._id}/event`;
  }

  /***
   * Destination Rule
   */
  static DestinationRuleStatusTopic(destination_rule: DestinationRule): string {
    return `destination-rulecy/${destination_rule._id}/status`;
  }

  static DestinationRuleEventTopic(destination_rule: DestinationRule): string {
    return `destination-rule/${destination_rule._id}/event`;
  }

  /***
   * Envoy Filter
   */
  static EnvoyFilterStatusTopic(envoy_filter: EnvoyFilter): string {
    return `envoy-filter/${envoy_filter._id}/status`;
  }

  static EnvoyFilterEventTopic(envoy_filter: EnvoyFilter): string {
    return `envoy-filter/${envoy_filter._id}/event`;
  }

  /***
   * Limit Range
   */
  static LimitRangeStatusTopic(limit_range: LimitRange): string {
    return `limit-range/${limit_range._id}/status`;
  }

  static LimitRangeEventTopic(limit_range: LimitRange): string {
    return `limit-range/${limit_range._id}/event`;
  }

  /***
   * Resource Quota
   */
  static ResourceQuotaStatusTopic(resource_quota: ResourceQuota): string {
    return `resource-quota/${resource_quota._id}/status`;
  }

  static ResourceQuotaEventTopic(resource_quota: ResourceQuota): string {
    return `resource-quota/${resource_quota._id}/event`;
  }

  /***
   * Deployment
   */
  static DeploymentStatusTopic(deployment: Deployment): string {
    return `deployment/${deployment._id}/status`;
  }

  static DeploymentEventTopic(deployment: Deployment): string {
    return `deployment/${deployment._id}/event`;
  }

  /***
   * Stateful Set
   */
  static StatefulSetStatusTopic(statefulSet: StatefulSet): string {
    return `stateful-set/${statefulSet._id}/status`;
  }

  static StatefulSetEventTopic(statefulSet: StatefulSet): string {
    return `stateful-set/${statefulSet._id}/event`;
  }

  /***
   * Daemon Set
   */
  static DaemonSetStatusTopic(daemonSet: DaemonSet): string {
    return `daemon-set/${daemonSet._id}/status`;
  }

  static DaemonSetEventTopic(daemonSet: DaemonSet): string {
    return `daemon-set/${daemonSet._id}/event`;
  }

  /***
   * Replica Set
   */
  static ReplicaSetStatusTopic(replicaSet: ReplicaSet): string {
    return `replica-set/${replicaSet._id}/status`;
  }

  static ReplicaSetEventTopic(replicaSet: ReplicaSet): string {
    return `replica-set/${replicaSet._id}/event`;
  }

  /***
   * Service
   */
  static ServiceStatusTopic(service: Service): string {
    return `service/${service._id}/status`;
  }

  static ServiceEventTopic(service: Service): string {
    return `service/${service._id}/event`;
  }

  /***
   * Persistent Volume Claim
   */
  static PersistentVolumeClaimStatusTopic(
    persistentVolumeClaim: PersistentVolumeClaim
  ): string {
    return `persistent-volume-claim/${persistentVolumeClaim._id}/status`;
  }

  static PersistentVolumeClaimEventTopic(
    persistentVolumeClaim: PersistentVolumeClaim
  ): string {
    return `persistent-volume-claim/${persistentVolumeClaim._id}/event`;
  }

  /***
   * Secret
   */
  static SecretStatusTopic(secret: Secret): string {
    return `secret/${secret._id}/status`;
  }

  static SecretEventTopic(secret: Secret): string {
    return `secret/${secret._id}/event`;
  }

  /***
   * Issuer
   */
  static IssuerStatusTopic(issuer: Issuer): string {
    return `issuer/${issuer._id}/status`;
  }

  static IssuerEventTopic(issuer: Issuer): string {
    return `issuer/${issuer._id}/event`;
  }

  /***
   * Certificate
   */
  static CertificateStatusTopic(certificate: Certificate): string {
    return `certificate/${certificate._id}/status`;
  }

  static CertificateEventTopic(certificate: Certificate): string {
    return `certificate/${certificate._id}/event`;
  }

  /***
   * Bundle
   */
  static BundleStatusTopic(bundle: Bundle): string {
    return `bundle/${bundle._id}/status`;
  }

  static BundleEventTopic(bundle: Bundle): string {
    return `bundle/${bundle._id}/event`;
  }

  /***
   * Volume Snapshot
   */
  static VolumeSnapshotStatusTopic(volume_snapshot: VolumeSnapshot): string {
    return `volume-snapshot/${volume_snapshot._id}/status`;
  }

  static VolumeSnapshotEventTopic(volume_snapshot: VolumeSnapshot): string {
    return `volume-snapshot/${volume_snapshot._id}/event`;
  }

  /***
   * Network Policy
   */
  static NetworkPolicyStatusTopic(network_policy: NetworkPolicy): string {
    return `network-policy/${network_policy._id}/status`;
  }

  static NetworkPolicyEventTopic(network_policy: NetworkPolicy): string {
    return `network-policy/${network_policy._id}/event`;
  }

  /***
   * Cilium Network Policy
   */
  static CiliumNetworkPolicyStatusTopic(
    cilium_network_policy: CiliumNetworkPolicy
  ): string {
    return `cilium-network-policy/${cilium_network_policy._id}/status`;
  }

  static CiliumNetworkPolicyEventTopic(
    cilium_network_policy: CiliumNetworkPolicy
  ): string {
    return `cilium-network-policy/${cilium_network_policy._id}/event`;
  }

  /***
   * File Systems
   */
  static FileSystemStatusTopic(file_system: FileSystem): string {
    return `file-system/${file_system._id}/status`;
  }

  static FileSystemEventTopic(file_system: FileSystem): string {
    return `file-system/${file_system._id}/event`;
  }

  /***
   * Zookeeper
   */
  static ZookeeperStatusTopic(zookeeper: Zookeeper): string {
    return `zookeeper/${zookeeper._id}/status`;
  }

  static ZookeeperEventTopic(zookeeper: Zookeeper): string {
    return `zookeeper/${zookeeper._id}/event`;
  }

  /***
   * Postgres Cluster
   */
  static PostgresClusterStatusTopic(postgres_cluster: PostgresCluster): string {
    return `postgres-cluster/${postgres_cluster._id}/status`;
  }

  static PostgresClusterEventTopic(postgres_cluster: PostgresCluster): string {
    return `postgres-cluster/${postgres_cluster._id}/event`;
  }

  /***
   * Elasticsearch
   */
  static ElasticsearchStatusTopic(elasticsearch: Elasticsearch): string {
    return `elasticsearch/${elasticsearch._id}/status`;
  }

  static ElasticsearchEventTopic(elasticsearch: Elasticsearch): string {
    return `elasticsearch/${elasticsearch._id}/event`;
  }

  /***
   * Redis
   */
  static RedisStatusTopic(redis: Redis): string {
    return `redis/${redis._id}/status`;
  }

  static RedisEventTopic(redis: Redis): string {
    return `redis/${redis._id}/event`;
  }

  /***
   * Kibana
   */
  static KibanaTopic(kibana: Kibana): string {
    return `kibana/${kibana._id}`;
  }

  static KibanaStatusTopic(kibana: Kibana): string {
    return `kibana/${kibana._id}/status`;
  }

  static KibanaEventTopic(kibana: Kibana): string {
    return `kibana/${kibana._id}/event`;
  }

  /***
   * Temporal Workflow
   */
  static TemporalWorkflowStatusTopic(
    temporal_workflow: TemporalWorkflow
  ): string {
    return `temporal-workflow/${temporal_workflow._id}/status`;
  }

  static TemporalWorkflowEventTopic(
    temporal_workflow: TemporalWorkflow
  ): string {
    return `temporal-workflow/${temporal_workflow._id}/event`;
  }

  /***
   * S3 Bucket
   */
  static S3BucketStatusTopic(s3_bucket: S3Bucket): string {
    return `s3-bucket/${s3_bucket._id}/status`;
  }

  static S3BucketEventTopic(s3_bucket: S3Bucket): string {
    return `s3-bucket/${s3_bucket._id}/event`;
  }

  /***
   * HDFS Cluster
   */
  static HdfsClusterTopic(hdfs_cluster: HdfsCluster): string {
    return `hdfs-cluster/${hdfs_cluster._id}`;
  }
  static HdfsClusterStatusTopic(hdfs_cluster: HdfsCluster): string {
    return `hdfs-cluster/${hdfs_cluster._id}/status`;
  }

  static HdfsClusterEventTopic(hdfs_cluster: HdfsCluster): string {
    return `hdfs-cluster/${hdfs_cluster._id}/event`;
  }

  /***
   * Ide
   */
  static IdeStatusTopic(ide: Ide): string {
    return `ide/${ide._id}/status`;
  }

  static IdeEventTopic(ide: Ide): string {
    return `ide/${ide._id}/event`;
  }

  /***
   * Notebook
   */
  static NotebookStatusTopic(notebook: Notebook): string {
    return `notebook/${notebook._id}/status`;
  }

  static NotebookEventTopic(notebook: Notebook): string {
    return `notebook/${notebook._id}/event`;
  }

  /***
   * Kafka
   */
  static KafkaStatusTopic(kafka: Kafka): string {
    return `kafka/${kafka._id}/status`;
  }

  static KafkaEventTopic(kafka: Kafka): string {
    return `kafka/${kafka._id}/event`;
  }

  /***
   * Kafka Connect
   */
  static KafkaConnectTopic(kafka_connect: KafkaConnect): string {
    return `kafka-connect/${kafka_connect._id}`;
  }

  static KafkaConnectStatusTopic(kafka_connect: KafkaConnect): string {
    return `kafka-connect/${kafka_connect._id}/status`;
  }

  static KafkaConnectEventTopic(kafka_connect: KafkaConnect): string {
    return `kafka-connect/${kafka_connect._id}/event`;
  }

  /***
   * Kafka Connector
   */
  static KafkaConnectorTopic(kafka_connector: KafkaConnector): string {
    return `kafka-connector/${kafka_connector._id}`;
  }

  static KafkaConnectorStatusTopic(kafka_connector: KafkaConnector): string {
    return `kafka-connector/${kafka_connector._id}/status`;
  }

  static KafkaConnectorEventTopic(kafka_connector: KafkaConnector): string {
    return `kafka-connector/${kafka_connector._id}/event`;
  }

  /***
   * Kafka Topic
   */
  static KafkaTopicStatusTopic(kafka_topic: KafkaTopic): string {
    return `kafka-topic/${kafka_topic._id}/status`;
  }

  static KafkaTopicEventTopic(kafka_topic: KafkaTopic): string {
    return `kafka-topic/${kafka_topic._id}/event`;
  }

  /***
   * Kafka User
   */
  static KafkaUserStatusTopic(kafka_user: KafkaUser): string {
    return `kafka-user/${kafka_user._id}/status`;
  }

  static KafkaUserEventTopic(kafka_user: KafkaUser): string {
    return `kafka-user/${kafka_user._id}/event`;
  }

  /***
   * Querybook
   */
  static QuerybookStatusTopic(querybook: Querybook): string {
    return `querybook/${querybook._id}/status`;
  }

  static QuerybookEventTopic(querybook: Querybook): string {
    return `querybook/${querybook._id}/event`;
  }

  /***
   * Prometheus
   */
  static PrometheusStatusTopic(prometheus: Prometheus): string {
    return `prometheus/${prometheus._id}/status`;
  }

  static PrometheusEventTopic(prometheus: Prometheus): string {
    return `prometheus/${prometheus._id}/event`;
  }

  /***
   * Service Monitor
   */
  static ServiceMonitorTopic(service_monitor: ServiceMonitor): string {
    return `service-monitor/${service_monitor._id}`;
  }

  static ServiceMonitorStatusTopic(service_monitor: ServiceMonitor): string {
    return `service-monitor/${service_monitor._id}/status`;
  }

  static ServiceMonitorEventTopic(service_monitor: ServiceMonitor): string {
    return `service-monitor/${service_monitor._id}/event`;
  }

  /***
   * Alertmanager
   */
  static AlertmanagerStatusTopic(alertmanager: Alertmanager): string {
    return `alertmanager/${alertmanager._id}/status`;
  }

  static AlertmanagerEventTopic(alertmanager: Alertmanager): string {
    return `alertmanager/${alertmanager._id}/event`;
  }

  /***
   * Alertmanager Config
   */
  static AlertmanagerConfigStatusTopic(
    alertmanager_config: AlertmanagerConfig
  ): string {
    return `alertmanager-config/${alertmanager_config._id}/status`;
  }

  static AlertmanagerConfigEventTopic(
    alertmanager_config: AlertmanagerConfig
  ): string {
    return `alertmanager-config/${alertmanager_config._id}/event`;
  }

  /***
   * Percona Server Mongo DB
   */
  static PerconaServerMongoDbTopic(
    percona_server_mongo_db: PerconaServerMongoDb
  ): string {
    return `percona-server-mongo-db/${percona_server_mongo_db._id}`;
  }

  static PerconaServerMongoDbStatusTopic(
    percona_server_mongo_db: PerconaServerMongoDb
  ): string {
    return `percona-server-mongo-db/${percona_server_mongo_db._id}/status`;
  }

  static PerconaServerMongoDbEventTopic(
    percona_server_mongo_db: PerconaServerMongoDb
  ): string {
    return `percona-server-mongo-db/${percona_server_mongo_db._id}/event`;
  }

  /***
   * Percona Server Mongo DB Backup
   */
  static PerconaServerMongoDbBackupTopic(
    percona_server_mongo_db_backup: PerconaServerMongoDbBackup
  ): string {
    return `percona-server-mongo-db-backup/${percona_server_mongo_db_backup._id}`;
  }

  static PerconaServerMongoDbBackupStatusTopic(
    percona_server_mongo_db_backup: PerconaServerMongoDbBackup
  ): string {
    return `percona-server-mongo-db-backup/${percona_server_mongo_db_backup._id}/status`;
  }

  static PerconaServerMongoDbBackupEventTopic(
    percona_server_mongo_db_backup: PerconaServerMongoDbBackup
  ): string {
    return `percona-server-mongo-db-backup/${percona_server_mongo_db_backup._id}/event`;
  }

  /***
   * Percona Server Mongo DB Restore
   */
  static PerconaServerMongoDbRestoreTopic(
    percona_server_mongo_db_restore: PerconaServerMongoDbRestore
  ): string {
    return `percona-server-mongo-db-restore/${percona_server_mongo_db_restore._id}`;
  }

  static PerconaServerMongoDbRestoreStatusTopic(
    percona_server_mongo_db_restore: PerconaServerMongoDbRestore
  ): string {
    return `percona-server-mongo-db-restore/${percona_server_mongo_db_restore._id}/status`;
  }

  static PerconaServerMongoDbRestoreEventTopic(
    percona_server_mongo_db_restore: PerconaServerMongoDbRestore
  ): string {
    return `percona-server-mongo-db-restore/${percona_server_mongo_db_restore._id}/event`;
  }

  /***
   * Percona XtraDB Cluster
   */
  static PerconaXtraDbClusterTopic(
    percona_xtra_db_cluster: PerconaXtraDbCluster
  ): string {
    return `percona-xtra-db-cluster/${percona_xtra_db_cluster._id}`;
  }

  static PerconaXtraDbClusterStatusTopic(
    percona_xtra_db_cluster: PerconaXtraDbCluster
  ): string {
    return `percona-xtra-db-cluster/${percona_xtra_db_cluster._id}/status`;
  }

  static PerconaXtraDbClusterEventTopic(
    percona_xtra_db_cluster: PerconaXtraDbCluster
  ): string {
    return `percona-xtra-db-cluster/${percona_xtra_db_cluster._id}/event`;
  }

  /***
   * Percona XtraDB Cluster Backup
   */
  static PerconaXtraDbClusterBackupTopic(
    percona_xtra_db_cluster_backup: PerconaXtraDbClusterBackup
  ): string {
    return `percona-xtra-db-cluster-backup/${percona_xtra_db_cluster_backup._id}`;
  }

  static PerconaXtraDbClusterBackupStatusTopic(
    percona_xtra_db_cluster_backup: PerconaXtraDbClusterBackup
  ): string {
    return `percona-xtra-db-cluster-backup/${percona_xtra_db_cluster_backup._id}/status`;
  }

  static PerconaXtraDbClusterBackupEventTopic(
    percona_xtra_db_cluster_backup: PerconaXtraDbClusterBackup
  ): string {
    return `percona-xtra-db-cluster-backup/${percona_xtra_db_cluster_backup._id}/event`;
  }

  /***
   * Percona XtraDB Cluster Restore
   */
  static PerconaXtraDbClusterRestoreTopic(
    percona_xtra_db_cluster_restore: PerconaXtraDbClusterRestore
  ): string {
    return `percona-xtra-db-cluster-restore/${percona_xtra_db_cluster_restore._id}`;
  }

  static PerconaXtraDbClusterRestoreStatusTopic(
    percona_xtra_db_cluster_restore: PerconaXtraDbClusterRestore
  ): string {
    return `percona-xtra-db-cluster-restore/${percona_xtra_db_cluster_restore._id}/status`;
  }

  static PerconaXtraDbClusterRestoreEventTopic(
    percona_xtra_db_cluster_restore: PerconaXtraDbClusterRestore
  ): string {
    return `percona-xtra-db-cluster-restore/${percona_xtra_db_cluster_restore._id}/event`;
  }

  /***
   * Workflow
   */
  static WorkflowTopic(workflow: Workflow): string {
    return `workflow/${workflow._id}`;
  }

  static WorkflowStatusTopic(workflow: Workflow): string {
    return `workflow/${workflow._id}/status`;
  }

  static WorkflowEventTopic(workflow: Workflow): string {
    return `workflow/${workflow._id}/event`;
  }

  /***
   * Notebooks
   */
  static NotebookTopic(notebook: Notebook): string {
    return `notebook/${notebook._id}`;
  }

  /***
   * IDEs
   */
  static IdeTopic(ide: Ide): string {
    return `ide/${ide._id}`;
  }
}
