export class MessagePayload<T> {
  title: string;
  description: string;
  icon: string;
  topic: string;
  success: boolean;
  message: string;
  data: T;

  constructor({
    title,
    description,
    icon,
    topic,
    success,
    message,
    data,
  }: {
    title: string;
    description: string;
    icon: string;
    topic: string;
    success: boolean;
    message: string;
    data: T;
  }) {
    this.title = title;
    this.description = description;
    this.icon = icon;
    this.topic = topic;
    this.success = success;
    this.message = message;
    this.data = data;
  }

  json(): string {
    return JSON.stringify({
      success: this.success,
      message: this.message,
      data: this.data,
    });
  }
}
