import { createReducer, on } from '@ngrx/store';
import { MenuActions } from '../actions';
import { MenuItem } from '../../models/menu.model';

export interface MenuState {
  items: Array<MenuItem>;
}

export const initialMenuState: MenuState = {
  items: [],
};

export const menuReducer = createReducer(
  initialMenuState,

  on(MenuActions.clearMenu, (state) => {
    return {
      ...state,
      items: [],
    };
  }),

  on(MenuActions.addMenuItem, (state, { item }) => {
    return {
      ...state,
      items: [...state.items, item],
    };
  }),

  on(MenuActions.addMenuItems, (state, { items }) => {
    return {
      ...state,
      items: [...state.items, ...items],
    };
  })
);
