import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions';
import { KeycloakEventType } from 'keycloak-angular';

export interface AuthState {
  jwt: string;
  status: KeycloakEventType;
}

export const initialAuthState: AuthState = {
  jwt: undefined,
  status: undefined,
};

export const authReducer = createReducer(
  initialAuthState,

  on(AuthActions.login, (state, action) => {
    return {
      ...state,
      jwt: action.jwt,
    };
  }),

  on(AuthActions.updateStatus, (state, action) => {
    return {
      ...state,
      status: action.status,
    };
  }),

  on(AuthActions.logout, (state, action) => {
    return {
      ...state,
      jwt: undefined,
    };
  })
);
